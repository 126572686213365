import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { db, signIn } from '../../lib/firebase';
import { doc, setDoc, collection, query, where, getDocs, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { UserPlus, LogIn } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { auth } from '../../lib/firebase';
import { AccessLevel } from '../../enums/AccessLevel';

export default function UserLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.from?.pathname || sessionStorage.getItem('redirectPath') || '/';
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [accessLevel, setAccessLevel] = useState('');

  const handlePasswordReset = async () => {
    if (!email) {
      toast.error('Please enter your email address first.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent!');
    } catch (err: any) {
      console.error('Password reset error:', err);
      toast.error('Failed to send password reset email. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (isSignUp) {
        // Check if group code is valid
        let groupData = null;
        let groupSnapshot = null;
        
        if (groupCode) {
          const groupsRef = collection(db, 'groups');
          const q = query(groupsRef, where('group_code', '==', groupCode));
          const snapshot = await getDocs(q);
          
          if (snapshot.empty) {
            toast.error('Invalid group code. Please check and try again.');
            setLoading(false);
            return;
          }
          
          groupSnapshot = snapshot;
          groupData = snapshot.docs[0].data();
          
          // Check if group has available seats
          if (groupData.seats_remaining <= 0) {
            toast.error('This group is currently full. Please contact support.');
            setLoading(false);
            return;
          }
          
          // Check if group membership is still valid
          const currentDate = new Date();
          const startDate = new Date(groupData.start_date);
          const endDate = new Date(groupData.end_date);
          
          if (currentDate < startDate || currentDate > endDate) {
            toast.error('This group code is not currently active.');
            setLoading(false);
            return;
          }
        }

        const timestamp = new Date().toISOString();

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userId = userCredential.user.uid;
        
        // Prepare user data
        const userData = {
          firstName,
          lastName,
          email: email.toLowerCase(),
          group: groupData ? snapshot.docs[0].id : null,
          access_level: groupData ? 'group_membership' : AccessLevel.PersonalMembership,
          createdAt: timestamp,
          membership_start: groupData ? timestamp : null,
          membership_end: groupData ? groupData.end_date : null,
          signup_source: 'OLD UserLogin'
        };
        setAccessLevel(userData.access_level);
        
        // Create user document
        await setDoc(doc(db, 'users', userId), userData);
        
        // Update group if joining one
        if (groupData) {
          const groupRef = doc(db, 'groups', groupSnapshot.docs[0].id);
          await updateDoc(groupRef, {
            members: arrayUnion(userId),
            seats_used: (groupData.seats_used || 0) + 1,
            seats_remaining: Math.max(0, (groupData.seats_remaining || 0) - 1)
          });
        }
        
        // Sign in the user immediately after signup
        await signInWithEmailAndPassword(auth, email, password);
        // Clear any stored redirect path after successful signup
        sessionStorage.removeItem('redirectPath');
        navigate(redirectPath, { replace: true });
        toast.success('Welcome to Finding Blueberries!');
      } else {
        const userCredential = await signIn(email, password);
        const userDoc = await getDoc(doc(db, 'users', userCredential.uid));
        setAccessLevel(userDoc.data()?.access_level || '');
        // Clear any stored redirect path after successful login
        sessionStorage.removeItem('redirectPath');
        navigate(redirectPath, { replace: true });
        toast.success('Welcome back!');
      }
    } catch (err: any) {
      console.error('Auth error:', err);
      const errorMessage = err.code === 'auth/wrong-password'
        ? 'Invalid email or password'
        : err.code === 'auth/user-not-found'
        ? 'No account found with this email'
        : err.code === 'auth/email-already-in-use'
        ? 'An account already exists with this email'
        : 'An error occurred. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage, {
        duration: 4000,
        position: 'top-center'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center">
          <div className="mx-auto h-12 w-12 bg-purple-100 rounded-full flex items-center justify-center">
            {isSignUp ? (
              <UserPlus className="h-6 w-6 text-purple-600" />
            ) : (
              <LogIn className="h-6 w-6 text-purple-600" />
            )}
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {isSignUp ? 'Create an account' : 'Sign in to continue'}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
            <button
              onClick={() => navigate('/purchase')}
              className="font-medium text-indigo-600 hover:text-indigo-700 underline"
            >
              {isSignUp ? 'Sign in' : 'Sign up'}
            </button>
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            {isSignUp && (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required={isSignUp}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required={isSignUp}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>
            )}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
            {isSignUp && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Group Code (Optional)
                </label>
                <input
                  type="text"
                  value={groupCode}
                  onChange={(e) => setGroupCode(e.target.value)}
                  placeholder="Enter group code if you have one"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>
            )}

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete={isSignUp ? 'new-password' : 'current-password'}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              />
            </div>
          </div>

          {error && (
            <div className="text-red-600 text-sm text-center">{error}</div>
          )}

          {!isSignUp && (
            <div className="text-right">
              <button
                type="button"
                onClick={handlePasswordReset}
                className="text-sm text-indigo-600 hover:text-indigo-700 underline"
              >
                Forgot password?
              </button>
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Processing...' : (isSignUp ? 'Create account' : 'Sign in')}
          </button>
        </form>
      </div>
    </div>
  );
}