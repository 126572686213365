import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import UserPicker from '@/components/ui/UserPicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Subscription, generateSubscriptionCode, verifySubscriptionCodeUnique, SubscriptionSource, PricePlan, RenewalStatus, Transaction, TransactionType } from '../../../lib/dal/subscriptions';
import { Duration } from '@/enums/PurchaseEnums';
import { useAuth } from '@/contexts/AuthContext';

interface AddSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (subscription: Partial<Subscription>) => void;
}

export default function AddSubscriptionModal({ isOpen, onClose, onSave }: AddSubscriptionModalProps) {
  const [source, setSource] = useState<SubscriptionSource>(SubscriptionSource.CERTIFICATION);
  const [adminId, setAdminId] = useState('');
  const [seats, setSeats] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [description, setDescription] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    const initCode = async () => {
      const code = await generateSubscriptionCode();
      setCode(code);
    };
    initCode();
  }, []);

  const validateCode = async (newCode: string) => {
    const isUnique = await verifySubscriptionCodeUnique(newCode);
    setIsCodeValid(isUnique);
    return isUnique;
  };

  const handleCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = e.target.value.toUpperCase();
    setCode(newCode);
    await validateCode(newCode);
  };

  const handleSourceChange = (uiValue: 'Certified' | 'Sponsored') => {
    setSource(uiValue === 'Certified' ? SubscriptionSource.CERTIFICATION : SubscriptionSource.SPONSORED);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isCodeValid) return;

    let pricePlan: PricePlan = {
      planID: '00002',
      planName: 'Certified Subscription',
      description: 'Certified: ' + description,
      duration: Duration.ANNUAL,
      price: 0
    }
    if (source === SubscriptionSource.SPONSORED) {
      pricePlan = {
        planID: '0001',
        planName: 'Sponsored Subscription',
        description: 'Sponsored: ' + description,
        duration: Duration.ANNUAL,
        price: 0
      };
    }

    const transaction: Transaction = {
      date: new Date().toISOString(),
      type: TransactionType.ADMIN_ACTION,
      amount: 0,
      description: `Subscription created from Admin Tool`,
      user_id: user?.uid || '',
    }
    

    const newSubscription: Subscription = {
        subscription_source: source,
        admin_id: adminId,
        certified_seats: seats,
        paid_seats: 0,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        code: code,
        price_plan: pricePlan,
        renewal_status: RenewalStatus.ACTIVE,
        history: [transaction],
        members: [],
      };
    
    onSave(newSubscription);
    onClose();
  };

  const isFormValid = isCodeValid && adminId !== '';

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-lg p-6 max-w-md w-full">
          <Dialog.Title className="text-lg font-medium mb-4">Add New Subscription</Dialog.Title>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Subscription Source</label>
              <div className="flex gap-4">
                <label className="flex-1">
                  <div className="relative">
                    <input
                      type="radio"
                      name="source"
                      value="Certified"
                      checked={source === SubscriptionSource.CERTIFICATION}
                      onChange={(e) => handleSourceChange('Certified')}
                      className="sr-only"
                    />
                    <div className={`p-3 border rounded-lg cursor-pointer ${
                      source === SubscriptionSource.CERTIFICATION ? 'border-indigo-600 bg-indigo-50' : 'border-gray-200'
                    }`}>
                      <div className="font-medium">Certified</div>
                      <div className="text-sm text-gray-500">Subscriptions for Certification program</div>
                    </div>
                  </div>
                </label>
                
                <label className="flex-1">
                  <div className="relative">
                    <input
                      type="radio"
                      name="source"
                      value="Sponsored"
                      checked={source === SubscriptionSource.SPONSORED}
                      onChange={(e) => handleSourceChange('Sponsored')}
                      className="sr-only"
                    />
                    <div className={`p-3 border rounded-lg cursor-pointer ${
                      source === SubscriptionSource.SPONSORED ? 'border-indigo-600 bg-indigo-50' : 'border-gray-200'
                    }`}>
                      <div className="font-medium">Sponsored</div>
                      <div className="text-sm text-gray-500">Finding Blueberries Sponsored (Free)</div>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="e.g., Q4 2023 Training Batch"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Admin</label>
              <UserPicker
                placeholder="Search for an admin... (case sensitive)"
                selectedUserId={adminId}
                onSelect={(userId) => setAdminId(userId)}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Included Seats</label>
                <input
                  type="number"
                  min="1"
                  max="1000"
                  value={seats}
                  onChange={(e) => setSeats(Number(e.target.value))}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Code</label>
                <input
                  type="text"
                  value={code}
                  onChange={handleCodeChange}
                  style={{ textTransform: 'uppercase' }}
                  className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 ${
                    isCodeValid ? 'border-gray-300 focus:border-indigo-500' : 'border-red-300 focus:border-red-500'
                  }`}
                />
                {!isCodeValid && (
                  <p className="mt-1 text-sm text-red-600">This code is already in use</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => {
                    setStartDate(date);
                    if (date > endDate) {
                      setEndDate(date);
                    }
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  dateFormat="MM/dd/yyyy"
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        padding: 10
                      }
                    }
                  ]}
                  popperPlacement="bottom-start"
                  popperProps={{
                    strategy: "fixed"
                  }}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  minDate={startDate}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  dateFormat="MM/dd/yyyy"
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        padding: 10
                      }
                    }
                  ]}
                  popperPlacement="bottom-start"
                  popperProps={{
                    strategy: "fixed"
                  }}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-3 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isFormValid}
                className={`px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md ${
                  isFormValid 
                    ? 'bg-indigo-600 hover:bg-indigo-700'
                    : 'bg-indigo-300 cursor-not-allowed'
                }`}
              >
                Add Subscription
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
