import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, ChevronDown, ChevronUp, Download, Share2 } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { db } from '../../lib/firebase';
import { PDFGenerator } from './PDFGenerator';
import type { CompletedTheme } from '../../lib/firebase/types';
import { createAndCopyShareLink } from '../../lib/utils/share';

interface ThemeSummaryViewerProps {
  isOpen: boolean;
  onClose: () => void;
  completedThemeId: string;
  userId: string;
}

export default function ThemeSummaryViewer({
  isOpen,
  onClose,
  completedThemeId,
  userId
}: ThemeSummaryViewerProps) {
  const [completedTheme, setCompletedTheme] = useState<CompletedTheme | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showResponses, setShowResponses] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);

  const handleShare = async () => {
    if (!completedTheme) return;

    try {
      await createAndCopyShareLink({
        summary: completedTheme.summary,
        userId
      });
    } catch (error) {
      console.error('Error in handleShare:', error);
    }
  };

  // Memoize onClose if it's recreated by parent
  const memoizedOnClose = React.useCallback(onClose, []);

  React.useEffect(() => {
    let mounted = true;

    const loadTheme = async () => {
      if (!isOpen || !completedThemeId || !userId) return;

      const maxRetries = 3;
      const baseDelay = 1000; // 1 second

      const attemptLoad = async (retry: number): Promise<void> => {
        if (!mounted) return;

        try {
          setLoading(true);
          const docRef = doc(db, 'completedThemes', completedThemeId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists() && mounted) {
            setCompletedTheme({ id: docSnap.id, ...docSnap.data() } as CompletedTheme);
          } else if (mounted) {
            if (retry < maxRetries) {
              console.log(`Attempt ${retry + 1}: Document not found, retrying...`);
              const delay = baseDelay * Math.pow(2, retry);
              await new Promise(resolve => setTimeout(resolve, delay));
              return attemptLoad(retry + 1);
            }
            toast.error('Unable to load theme summary');
            memoizedOnClose();
          }
        } catch (error) {
          if (mounted) {
            console.error('Error loading theme summary:', error);
            toast.error('Failed to load theme summary');
            memoizedOnClose();
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      };

      await attemptLoad(0);
    };

    loadTheme();

    return () => {
      mounted = false;
    };
  }, [isOpen, completedThemeId, userId, memoizedOnClose]);

  if (!isOpen || !completedTheme) return null;

  return (
    <AnimatePresence mode="wait">
      {isOpen && completedThemeId && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
            <div className="bg-white rounded-xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <div className="sticky top-0 bg-white border-b px-6 py-4 flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={handleShare}
                    className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
                  >
                    <Share2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => setShowPDFModal(true)}
                    className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
                  >
                    <Download className="w-5 h-5" />
                  </button>
                </div>
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              
              <div className="p-6">
                {loading ? (
                  <div className="flex items-center justify-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-2 border-indigo-600 border-t-transparent"></div>
                  </div>
                ) : error ? (
                  <div className="text-center py-8 text-red-600">{error}</div>
                ) : completedTheme ? (
                  <div className="space-y-6">
                    <div>
                      <h1 className="text-3xl font-bold text-indigo-900 mb-2">
                        {completedTheme.summaryTitle || 
                         (completedTheme.themeType === 'experience_summary' 
                          ? 'Year In Review Summary'
                          : completedTheme.themeType === 'meta_summary'
                          ? completedTheme.theme_name || 'Meta Summary'
                          : `${completedTheme.themeTitle || completedTheme.theme_name || 'Theme'} Summary`)}
                      </h1>
                      {completedTheme.createdFor && (
                        <p className="text-indigo-600">
                          Created for {completedTheme.createdFor}
                        </p>
                      )}
                    </div>

                    <button
                      onClick={() => setShowResponses(!showResponses)}
                      className="w-full flex items-center justify-between p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg hover:from-indigo-100 hover:to-purple-100 transition-colors"
                    >
                      <div className="flex items-center space-x-2">
                        <MessageCircle className="w-5 h-5 text-indigo-600" />
                        <span className="font-medium text-indigo-900">Your Responses</span>
                      </div>
                      {showResponses ? (
                        <ChevronUp className="w-5 h-5 text-indigo-600" />
                      ) : (
                        <ChevronDown className="w-5 h-5 text-indigo-600" />
                      )}
                    </button>

                    {showResponses && (
                      <div className="space-y-4 bg-white rounded-lg border border-indigo-100">
                        {completedTheme.responses.map((response) => (
                          <div key={response.questionId} className="p-4 border-b border-indigo-100 last:border-b-0">
                            <p className="font-medium text-indigo-900 mb-2">{response.question}</p>
                            <p className="text-gray-600 whitespace-pre-wrap">{response.content}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="prose prose-indigo max-w-none [&>ul]:list-none [&>ul]:pl-0 [&>ul>li]:relative [&>ul>li]:pl-6 [&>ul>li]:mb-2 [&>ul>li]:before:content-['•'] [&>ul>li]:before:absolute [&>ul>li]:before:left-0 [&>ul>li]:before:text-indigo-500">
                      <ReactMarkdown>{completedTheme.summary}</ReactMarkdown>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </motion.div>
        </>
      )}

      {completedTheme && (
        <PDFGenerator
          isOpen={showPDFModal}
          onClose={() => setShowPDFModal(false)}
          title={completedTheme.themeTitle || completedTheme.theme_name || 'Theme Summary'}
          content={completedTheme.summary}
          createdFor={completedTheme.createdFor}
          themeType={completedTheme.themeType}
          responses={completedTheme.responses.map(r => ({
            question: r.question,
            content: r.content
          }))}
        />
      )}
    </AnimatePresence>
  );
}