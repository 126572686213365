import React, { useState, useRef, useEffect } from 'react';
import { User } from '../../types';

interface MentionInputProps {
  onSend: (message: string, mentions: string[]) => void;
  packMembers: User[];
  placeholder?: string;
}

export default function MentionInput({ onSend, packMembers, placeholder = 'Type your message...' }: MentionInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [showMentions, setShowMentions] = useState(false);
  const [mentionSearch, setMentionSearch] = useState('');
  const [mentionStart, setMentionStart] = useState(-1);
  const [mentions, setMentions] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLDivElement>(null);

  // Filter members based on search text
  const filteredMembers = packMembers.filter(member => 
    mentionSearch ? 
      `${member.firstName} ${member.lastName}`.toLowerCase().includes(mentionSearch.toLowerCase()) 
      : true
  );

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const value = e.currentTarget.textContent || '';
    setInputValue(value);

    // Check for @ symbol
    const atPosition = value.lastIndexOf('@');
    const lastSpaceIndex = value.lastIndexOf(' ');
    
    if (atPosition !== -1 && atPosition > lastSpaceIndex) {
      const searchText = value.slice(atPosition + 1).split(/[\s\n]/)[0];
      console.log('@ detected:', { atPosition, searchText });
      setShowMentions(true);
      setMentionStart(atPosition);
      setMentionSearch(searchText);
      setSelectedIndex(0);
    } else {
      setShowMentions(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    console.log('Key pressed:', e.key, { showMentions, filteredMembers });
    
    if (showMentions && filteredMembers.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev < filteredMembers.length - 1 ? prev + 1 : prev
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
          break;
        case 'Enter':
          if (!e.shiftKey) {
            e.preventDefault();
            const selectedMember = filteredMembers[selectedIndex];
            if (selectedMember) {
              insertMention(selectedMember);
            }
          }
          break;
        case 'Tab':
          e.preventDefault();
          const selectedMember = filteredMembers[selectedIndex];
          if (selectedMember) {
            insertMention(selectedMember);
          }
          break;
        case 'Escape':
          setShowMentions(false);
          break;
      }
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const insertMention = (userData: User) => {
    if (inputRef.current) {
      const selection = window.getSelection();
      if (!selection?.rangeCount) return;
      
      const range = selection.getRangeAt(0);
      
      // Create mention span that stores ID but displays name
      const mentionSpan = document.createElement('span');
      mentionSpan.className = 'bg-indigo-100 text-indigo-800 rounded px-1 mx-0.5';
      mentionSpan.contentEditable = 'false';
      mentionSpan.setAttribute('data-user-id', userData.uid);
      // Display the full name
      mentionSpan.textContent = `@${userData.firstName} ${userData.lastName}`;
      // Add a hidden span with the actual ID that will be used in the message
      const hiddenSpan = document.createElement('span');
      hiddenSpan.style.display = 'none';
      hiddenSpan.textContent = `@${userData.uid}`;
      mentionSpan.appendChild(hiddenSpan);
      
      const textNode = range.startContainer;
      const text = textNode.textContent || '';
      const lastAtIndex = text.lastIndexOf('@');
      
      if (lastAtIndex !== -1) {
        const newRange = document.createRange();
        newRange.setStart(textNode, lastAtIndex);
        newRange.setEnd(textNode, range.startOffset);
        newRange.deleteContents();
        newRange.insertNode(mentionSpan);
        
        // Add space after mention
        const space = document.createTextNode(' ');
        newRange.setStartAfter(mentionSpan);
        newRange.collapse(true);
        newRange.insertNode(space);
        
        // Move cursor after space
        newRange.setStartAfter(space);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    }
    
    setShowMentions(false);
    setMentions([...mentions, userData.uid]);
    inputRef.current?.focus();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputRef.current) return;

    // Get the content with IDs instead of display names
    const messageContent = Array.from(inputRef.current.childNodes).map(node => {
      if (node instanceof HTMLSpanElement && node.getAttribute('data-user-id')) {
        // Get the hidden span with the ID
        const hiddenSpan = node.querySelector('span');
        return hiddenSpan ? hiddenSpan.textContent : node.textContent;
      }
      return node.textContent;
    }).join('');

    if (messageContent.trim()) {
      onSend(messageContent, mentions);
      inputRef.current.textContent = '';
      setInputValue('');
      setMentions([]);
      setShowMentions(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative flex items-center space-x-2">
        <div
          ref={inputRef}
          contentEditable
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none empty:before:content-[attr(placeholder)] empty:before:text-gray-400 min-h-[40px]"
          style={{ caretColor: 'black' }}
        />
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          Send
        </button>

        {showMentions && filteredMembers.length > 0 && (
          <div className="absolute top-full left-0 right-0 mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto z-10">
            {filteredMembers.map((member, index) => (
              <button
                key={member.uid}
                type="button"
                onClick={() => insertMention(member)}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2 ${
                  index === selectedIndex ? 'bg-indigo-50' : ''
                }`}
              >
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                  <span className="text-sm text-indigo-600">
                    {member.firstName?.[0] || '?'}
                  </span>
                </div>
                <span>{member.firstName} {member.lastName}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </form>
  );
} 