import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { FirebaseUserRepository, UserService, FbbUser } from '../../lib/dal/users';

interface UserPickerProps {
  onSelect: (userId: string) => void;
  placeholder?: string;
  className?: string;
  selectedUserId?: string | null;
}

export default function UserPicker({ 
  onSelect, 
  placeholder = "Search users by email or name...",
  className = "",
  selectedUserId = null
}: UserPickerProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FbbUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<FbbUser | null>(null);
  const userService = new UserService(new FirebaseUserRepository());

  useEffect(() => {
    const loadSelectedUser = async () => {
      if (selectedUserId) {
        try {
          const user = await userService.getUser(selectedUserId);
          setSelectedUser(user);
        } catch (error) {
          console.error('Error loading selected user:', error);
          setSelectedUser(null);
        }
      } else {
        setSelectedUser(null);
      }
    };

    loadSelectedUser();
  }, [selectedUserId]);

  const searchUsers = async (term: string) => {
    if (!term.trim() || term.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const allUsers = await userService.getAllUsers(term);
      const results = Object.values(allUsers)
        .filter(user => {
          const searchableText = `${user.email} ${user.firstName} ${user.lastName}`.toLowerCase();
          return searchableText.includes(term.toLowerCase());
        })
        .slice(0, 5);

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
      setSearchResults([]);
    }
  };

  return (
    <div className={className}>
      {selectedUser && (
        <div className="mb-2 p-2 bg-gray-50 rounded-md">
          <div className="font-medium">{selectedUser.firstName} {selectedUser.lastName}</div>
          <div className="text-sm text-gray-500">{selectedUser.email}</div>
        </div>
      )}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            searchUsers(e.target.value);
          }}
          placeholder={placeholder}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
        />
      </div>
      {searchResults.length > 0 && (
        <div className="mt-2 border border-gray-200 rounded-lg max-h-40 overflow-y-auto">
          {searchResults.map(user => (
            <div
              key={user.id}
              className="flex items-center justify-between p-2 hover:bg-gray-50 cursor-pointer"
              onClick={() => {
                onSelect(user.id);
                setSearchTerm('');
                setSearchResults([]);
              }}
            >
              <div>
                <div className="font-medium">{user.firstName} {user.lastName}</div>
                <div className="text-sm text-gray-500">{user.email}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
