import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { Transaction } from "../../../lib/dal/subscriptions"
import { useState, useEffect } from "react"
import { UserService, FirebaseUserRepository, FbbUser } from "../../../lib/dal/users"

interface SubscriptionHistoryProps {
  history?: Transaction[]
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function SubscriptionHistory({ history = [] }: SubscriptionHistoryProps) {
  const [users, setUsers] = useState<{[key: string]: FbbUser}>({});
  const userService = new UserService(new FirebaseUserRepository());

  const getUserName = async (userId: string) => {
    if (!userId) return "Unknown";
    
    // Return cached user if available
    if (users[userId]) {
      return `${users[userId].firstName} ${users[userId].lastName}`;
    }

    try {
      // Fetch and cache the user
      const user = await userService.getUser(userId);
      setUsers(prev => ({
        ...prev,
        [userId]: user
      }));
      return `${user.firstName} ${user.lastName}`;
    } catch (error) {
      console.error('Error loading user:', error);
      return userId;
    }
  };

  return (
    <div>
      <h2 className="text-xl text-gray-700 mb-4 flex items-center">
        Subscription Transactions
        <Badge variant="secondary" className="ml-2">
          {history.length}
        </Badge>
      </h2>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Amount (USD)</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>User</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {history.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell>{formatDate(transaction.date)}</TableCell>
              <TableCell>{transaction.type}</TableCell>
              <TableCell>${transaction.amount.toFixed(2)}</TableCell>
              <TableCell>{transaction.description}</TableCell>
              <TableCell>
                <UserNameCell userId={transaction.user_id} getUserName={getUserName} />
              </TableCell>
            </TableRow>
          ))}
          {history.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} className="text-center">No transaction history available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

// Add a new component to handle the async user name display
function UserNameCell({ userId, getUserName }: { userId: string, getUserName: (userId: string) => Promise<string> }) {
  const [name, setName] = useState(userId);

  useEffect(() => {
    getUserName(userId).then(setName);
  }, [userId]);

  return <>{name}</>;
}
