import React from 'react';
import { Pack } from '../../lib/firebase/types';
import { ChevronRight, Users, Plus, MoreVertical } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface PacksListProps {
  packs: Pack[];
  selectedPackId: string | null;
  onSelectPack: (pack: Pack) => void;
  onNewPack: () => void;
  onEditPack: (pack: Pack) => void;
  onDeletePack: (packId: string) => void;
  onLeavePack?: (packId: string) => void;
}

export default function PacksList({ 
  packs, 
  selectedPackId, 
  onSelectPack, 
  onNewPack,
  onEditPack,
  onDeletePack,
  onLeavePack
}: PacksListProps) {
  const [menuOpen, setMenuOpen] = React.useState<string | null>(null);
  const { user } = useAuth();

  const handleMenuClick = (e: React.MouseEvent, pack: Pack) => {
    e.stopPropagation();
    setMenuOpen(menuOpen === pack.id ? null : pack.id);
  };

  // Close menu when clicking outside
  React.useEffect(() => {
    const handleClickOutside = () => setMenuOpen(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="h-full flex flex-col overflow-hidden">
      <div className="flex-shrink-0 p-4 border-b">
        <button
          onClick={onNewPack}
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 flex items-center justify-center space-x-2"
        >
          <Plus className="h-4 w-4" />
          <span>New Pack</span>
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="divide-y divide-gray-200">
          {packs.map(pack => (
            <div
              key={pack.id}
              className={`relative w-full px-4 py-3 flex items-center space-x-3 hover:bg-gray-50 ${
                selectedPackId === pack.id ? 'bg-indigo-50' : ''
              }`}
            >
              <button
                className="flex-1 flex items-center"
                onClick={() => onSelectPack(pack)}
              >
                <div className="flex-shrink-0">
                  <Users className="h-6 w-6 text-gray-400" />
                </div>
                <div className="flex-1 text-left ml-3">
                  <h3 className="text-sm font-medium text-gray-900">
                    {pack.name || `Pack (${pack.members.length} members)`}
                  </h3>
                </div>
                <ChevronRight className="h-5 w-5 text-gray-400" />
              </button>
              
              <div className="relative">
                <button 
                  onClick={(e) => handleMenuClick(e, pack)}
                  className="p-1 rounded hover:bg-gray-100"
                >
                  <MoreVertical className="h-5 w-5 text-gray-400" />
                </button>

                {menuOpen === pack.id && (
                  <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-10 border">
                    {pack.leaderId === user?.uid && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditPack(pack);
                          setMenuOpen(null);
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Edit Pack
                      </button>
                    )}
                    {pack.leaderId !== user?.uid && onLeavePack && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (window.confirm('Are you sure you want to leave this pack?')) {
                            onLeavePack(pack.id);
                          }
                          setMenuOpen(null);
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
                      >
                        Leave Pack
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
