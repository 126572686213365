import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, setDoc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Edit2, Trash2, UserPlus, ArrowUpDown, Search, Copy, Wrench } from 'lucide-react';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { toast } from 'react-hot-toast';
import { AccessLevel } from '../../enums/AccessLevel';
import { 
  GroupService, 
  FirebaseGroupRepository, 
  Group,
  GroupMap
} from '../../lib/dal/groups';
import { FirebaseSubscriptionRepository, SubscriptionService, Subscription } from '../../lib/dal/subscriptions';
import { SubscriptionConstants } from '../../lib/dal/subscriptions';
import { Link } from 'react-router-dom';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  group?: string;
  access_level: 'free' | 'limited_trial' | 'group_membership' | 'personal_membership' | 'admin';
  membership_start?: string;
  membership_end?: string;
  displayName?: string;
  lastSignInTime?: string;
}

// interface User2 extends FbbUser {
//   displayName: string;
// }

export default function UsersTable() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterAccessLevel, setFilterAccessLevel] = useState('all');
  const [filterGroup, setFilterGroup] = useState('all');
  const [sortConfig, setSortConfig] = useState<{
    key: keyof User;
    direction: 'asc' | 'desc';
  }>({ key: 'createdAt', direction: 'desc' });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [allGroups, setAllGroups] = useState<GroupMap>({});
  const [allSubscriptions, setAllSubscriptions] = useState<{[key: string]: Subscription}>({});
  const ghostUser = '👻 GHOST 👻';
  const groupService = new GroupService(new FirebaseGroupRepository());
  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());

  useEffect(() => {
    fetchUsers();
    groupService.getAllGroups()
    .then(groups => {
      setAllGroups(groups);
    });

    const loadSubscriptions = async () => {
      const subs = await subscriptionService.getAllSubscriptions();
      const subsMap = subs.reduce((acc, sub) => {
        acc[sub.id] = sub;
        return acc;
      }, {});
      setAllSubscriptions(subsMap);
    };
    loadSubscriptions();
  }, []);


  const fetchUsers = async () => {
    try {
      console.log('Fetching users...');
      // Fetch users from Firestore Auth
      const fireBaseResponse = await fetch(`/api/user-admin?pageSize=100`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      if (!fireBaseResponse.ok) {
        const errorText = await fireBaseResponse.text();
        console.error('Fetch User response error:', errorText);
        throw new Error(`Fetch User response was not ok: ${fireBaseResponse.status} ${errorText}`);
      }
      const fireAuthData = await fireBaseResponse.json();
      if (!fireAuthData || !fireAuthData.users) {
        console.error('Invalid response data:', fireAuthData);
        throw new Error('Invalid response data structure');
      }
      console.log('Fetched users successfully:', fireAuthData);

      let usersData = fireAuthData.users.map(userRecord => ({
        id: userRecord.uid,
        email: userRecord.email,
        createdAt: new Date(userRecord.metadata.creationTime).toISOString(),
        lastSignInTime: userRecord.metadata.lastRefreshTime ? new Date(userRecord.metadata.lastRefreshTime).toISOString() : null,
      }));

      // append doc.data() to the users map
      const q = query(collection(db, 'users'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        const userIndex = usersData.findIndex(user => user.id === doc.id);
        if (userIndex !== -1) {
          usersData[userIndex] = {
            ...usersData[userIndex],
            ...data
          } as User;
        } else {
          console.log("User not found in firebase auth", doc.id);
          usersData.push({
            id: doc.id,
            ...data
          });
        }
      });

      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = async (user: User) => {
    try {
      const userInfo = `${user.id}`;
  
      await navigator.clipboard.writeText(userInfo);
      toast.success(`${user.id} copied to clipboard`);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy user info');
    }
  };

  const handleFixAccount = async (user: User) => {
    const timestamp = new Date().toISOString();
    // Create user document data
    const userData = {
      email: user.email.toLowerCase(),
      access_level: AccessLevel.Free,
      createdAt: timestamp,
    };

    // Create user document in Firestore
    await setDoc(doc(db, 'users', user.id), userData);
    toast.success('User Fix Applied');

  }

  const handleDelete = async (user: User) => {
    if (!window.confirm(`Are you sure you want to delete ${user.firstName} ${user.lastName}?`)) {
      return;
    }
    
    setLoading(true);

    try {
      // Delete Firebase Auth
      const response = await fetch('/api/user-admin', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.id })
      });

      if (!response.ok) {
        throw new Error('Delete User response was not ok');
      }

      // Delete Firestore user document
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data() as User;

      if (userData.group) {
        await groupService.removeMember(userData.group, user.id);
      }

      const results = await deleteDoc(userRef);
      console.log('results:', results);

      //TODO:  In the USER object, we need to use the groupid instead the group Code.


      setUsers(users.filter(u => u.id !== user.id));
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleUserUpdated = () => {
    fetchUsers();
  };

  const handleSort = (key: keyof User) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredAndSortedUsers = users
    .filter(user => {
      if (!user.firstName && !user.lastName && !user.access_level) {
        user.displayName = ghostUser;
        //user.access_level = 'unknown';
      } else if (user.firstName || user.lastName) {
        user.displayName = `${user.firstName} ${user.lastName}`;
      } else {
        user.displayName = 'MISSING USER INFO';
      }
      //user.access_level = user.access_level || 'unknown';
      const matchesSearch = 
        `${user.firstName} ${user.lastName} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      
      const matchesAccessLevel = 
        filterAccessLevel === 'all' || 
        filterAccessLevel === 'unknown' && !user.access_level ||
        user.access_level === filterAccessLevel;
      
      const matchesGroup = 
        filterGroup === 'all' || 
        user.group === filterGroup;
      
      return matchesSearch && matchesAccessLevel && matchesGroup;
    })
    .sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (sortConfig.key === 'firstName' || sortConfig.key === 'lastName') {
        return sortConfig.direction === 'asc'
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      }
      
      return sortConfig.direction === 'asc'
        ? aValue > bValue ? 1 : -1
        : bValue > aValue ? 1 : -1;
    });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  } 

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Users</h2>
          <div>
            <span className="text-sm text-gray-500">
            Users by filters: {filteredAndSortedUsers.length}
            </span>
          </div>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <UserPlus className="w-4 h-4" />
            <span>Add User</span>
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          
          <select
            value={filterAccessLevel}
            onChange={(e) => setFilterAccessLevel(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Access Levels</option>
            <option value="free">Free</option>
            <option value="limited_trial">Limited Trial</option>
            <option value="group_membership">Group Membership</option>
            <option value="personal_membership">Personal Membership</option>
            <option value="admin">Admin</option>
            <option value="unknown">Unknown</option>
          </select>
          
          <select
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Groups</option>
            {Object.values(allGroups).map(group => (
              <option key={group.id} value={group.id}>
                {group.group_name || group.group_code || 'Unknown Group'}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th 
                onClick={() => handleSort('firstName')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Name</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                onClick={() => handleSort('email')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Email</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <div className="flex items-center space-x-1">
                  <span>Subscriptions</span>
                </div>
              </th>
              <th 
                onClick={() => handleSort('group')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Group</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                onClick={() => handleSort('access_level')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Access Level</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                onClick={() => handleSort('membership_start')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Membership Period</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                onClick={() => handleSort('createdAt')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Date Added</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                onClick={() => handleSort('lastSignInTime')}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center space-x-1">
                  <span>Last Sign-In</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredAndSortedUsers.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {user.displayName}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {user.subscription_ids ? (
                    <div className="space-x-2">
                      {user.subscription_ids.map(subId => {
                        const sub = allSubscriptions[subId];
                        if (!sub) return null;
                        return (
                          <Link 
                            key={subId}
                            to={`/admin/subscription-admin?id=${subId}`} 
                            className="text-blue-600 hover:text-blue-900"
                          >
                            {sub.code === SubscriptionConstants.NOCODE ? '🫐 Ind' : sub.code}
                          </Link>
                        );
                      })}
                    </div>
                  ) : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {allGroups[user.group] ? allGroups[user.group].group_name : user.group || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    user.access_level === 'free' ? 'bg-gray-100 text-gray-800' :
                    user.access_level === 'limited_trial' ? 'bg-yellow-100 text-yellow-800' :
                    user.access_level === 'group_membership' ? 'bg-green-100 text-green-800' :
                    user.access_level === 'personal_membership' ? 'bg-blue-100 text-blue-800' :
                    user.access_level === 'admin' ? 'bg-purple-100 text-purple-800' :
                    'bg-red-100 text-blue-800'
                  }`}>
                    {user.access_level ? user.access_level.replace('_', ' ') : 'unknown'} 
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div>
                    {user.membership_start && (
                      <a href={`https://dashboard.stripe.com/subscriptions/${user.subscription_id}`} target='_blank' className="text-blue-600 hover:text-blue-900">
                      <div>Start: {new Date(user.membership_start).toLocaleDateString()}</div>
                      </a>
                    )}
                    {user.membership_end && (
                      <div>End: {new Date(user.membership_end).toLocaleDateString()}</div>
                    )}
                    {!user.membership_start && !user.membership_end && '-'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.lastSignInTime ? new Date(user.lastSignInTime).toLocaleDateString() : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex space-x-2">
                  <div className="relative group">
                    <button
                      onClick={() => handleCopy(user)}
                      className="p-1 text-blue-600 hover:text-blue-900"
                      title="Copy userID" // Simple native tooltip
                    >
                      <Copy className="w-4 h-4" />
                    </button>
                    
                    {/* Custom styled tooltip */}
                    <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 -top-8 left-1/2 transform -translate-x-1/2">
                      Copy UserID
                      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
                    </div>
                  </div>
                    <button
                      onClick={() => handleEdit(user)}
                      className="p-1 text-blue-600 hover:text-blue-900"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(user)}
                      className="p-1 text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                    {user.displayName === ghostUser && <div className="relative group">
                    <button
                      onClick={() => handleFixAccount(user)}
                      className="p-1 text-blue-600 hover:text-blue-900"
                      title="Fix Account" // Simple native tooltip
                    >
                      <Wrench className="w-4 h-4" />
                    </button>
                    
                    {/* Custom styled tooltip */}
                    <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 -top-8 left-1/2 transform -translate-x-1/2">
                      Fix account
                      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
                    </div>
                  </div>
}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <AddUserModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onUserAdded={fetchUsers}
      />
      
      {selectedUser && (
        <EditUserModal
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
          onUserUpdated={handleUserUpdated}
        />
      )}
    </div>
  );
}