import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FileText, Download, Share2, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { PDFGenerator } from '../shared/PDFGenerator';
import { useAuth } from '../../contexts/AuthContext';
import { getCompletedThemes, CompletedTheme } from '../../lib/firebase';
import { collection, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { getThemeById } from '../../lib/firebase/themes';
import { generateStructuredInfo } from '../../lib/openai';
import { createAndCopyShareLink } from '../../lib/utils/share';
import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { db } from '../../lib/firebase/config';

interface DashboardProps {
  onClose?: () => void;
}

interface CompletedTheme {
  id: string;
  userId: string;
  userName: string;
  themeId: string;
  themeTitle?: string;
  summaryTitle?: string;
  theme_name?: string;
  themeType?: 'theme' | 'experience_summary' | 'meta_summary';
  experienceId: string;
  experienceName?: string;
  responses: Array<{
    questionId: number;
    question: string;
    content: string;
    createdAt: string;
  }>;
  summary: string;
  completedAt: string;
  questions?: Array<{
    id: number;
    text: string;
  }>;
  createdFor?: string;
}

export default function Dashboard({ onClose }: DashboardProps) {
  const [activeTab, setActiveTab] = useState(() => {
    // Get the tab from URL first
    const params = new URLSearchParams(window.location.search);
    const urlTab = params.get('tab');
    if (urlTab) return urlTab;

    // If no URL tab, check if we're coming from packs
    const lastTab = localStorage.getItem('dashboardTab');
    const hasSelectedPack = localStorage.getItem('selectedPackId');
    
    // Only use packs as default if it was the last tab AND there's a selected pack
    if (lastTab === 'packs' && hasSelectedPack) {
      return 'packs';
    }

    // Default to activity
    return 'activity';
  });
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [themes, setThemes] = useState<CompletedTheme[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showDebug, setShowDebug] = useState(false);
  const [showOnlyExperienceSummaries, setShowOnlyExperienceSummaries] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [showSummaryViewer, setShowSummaryViewer] = useState(false);
  const [themeTitles, setThemeTitles] = useState<Record<string, string>>({});
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [experienceFilter, setExperienceFilter] = useState<string>('all');
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [selectedThemeForPDF, setSelectedThemeForPDF] = useState<CompletedTheme | null>(null);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isGeneratingMetaSummary, setIsGeneratingMetaSummary] = useState(false);
  const [metaSummaryResponse, setMetaSummaryResponse] = useState<any>(null);
  const [showMetaSummaryModal, setShowMetaSummaryModal] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [metaSummaryTitle, setMetaSummaryTitle] = useState('');
  const [additionalContext, setAdditionalContext] = useState('');

  // Pass selectedPackId to PacksTab if we're on the packs tab
  const selectedPackId = localStorage.getItem('selectedPackId');

  const handleDownloadPDF = (theme: CompletedTheme) => {
    setSelectedThemeForPDF(theme);
    setShowPDFModal(true);
  };

  const handleViewSummary = (theme: CompletedTheme) => {
    setSelectedThemeId(theme.id);
    setShowSummaryViewer(true);
  };

  const handleShare = async (theme: CompletedTheme) => {
    try {
      await createAndCopyShareLink({
        summary: theme.summary,
        userId: user?.uid || ''
      });
      toast.success('Share link copied to clipboard!');
    } catch (error) {
      console.error('Error in handleShare:', error);
      toast.error('Failed to create share link');
    }
  };

  const handleThemeSelect = (themeId: string) => {
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter(id => id !== themeId));
    } else {
      setSelectedThemes([...selectedThemes, themeId]);
    }
  };

  const handleDeleteSelectedThemes = async () => {
    setShowDeleteConfirm(false);
    try {
      await Promise.all(selectedThemes.map(async themeId => {
        const themeRef = doc(db, 'completedThemes', themeId);
        await deleteDoc(themeRef);
      }));
      setSelectedThemes([]);
      toast.success('Selected themes deleted successfully!');
      if (user) {
        const fetchedThemes = await getCompletedThemes(user.uid);
        setThemes(fetchedThemes);
      }
    } catch (error) {
      console.error('Error deleting themes:', error);
      toast.error('Failed to delete selected themes.');
    }
  };

  const handleTitleSubmit = async () => {
    setShowTitleModal(false);
    await handleGenerateMetaSummary();
  };

  const handleGenerateMetaSummary = async () => {
    if (!user || !userData) return;
    
    setIsGeneratingMetaSummary(true);
    setMetaSummaryResponse(null);
    try {
      const summaries = await Promise.all(selectedThemes.map(async themeId => {
        const theme = themes.find(t => t.id === themeId);
        return theme?.summary || '';
      }));

      const combinedSummary = summaries.join('\n\n---\n\n');

      const response = await generateStructuredInfo(
        combinedSummary,
        { model: "gpt-4", temperature: 0.7 },
        `Generate a concise meta-summary of these theme summaries, highlighting key patterns and insights. Focus on identifying recurring themes, personal growth patterns, and actionable insights. ${additionalContext ? `Additional Context to Consider: ${additionalContext}` : ''}`
      );

      if (!response?.data) {
        throw new Error("Failed to generate meta summary");
      }

      const selectedThemeData = await Promise.all(selectedThemes.map(async themeId => {
        const theme = themes.find(t => t.id === themeId);
        return {
          responses: theme?.responses || [],
          summary: theme?.summary || ''
        };
      }));

      const combinedResponses = selectedThemeData.flatMap(theme => theme.responses);

      const docRef = await addDoc(collection(db, 'completedThemes'), {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        theme_name: metaSummaryTitle || 'Meta Summary',
        channel: 'findingblueberries-dashboard',
        summary: response.data,
        completedAt: new Date().toISOString(),
        themeType: 'meta_summary',
        responses: combinedResponses,
        themeId: 'meta-summary',
        additionalContext: additionalContext || ''
      });

      const updatedThemes = await getCompletedThemes(user.uid);
      setThemes(updatedThemes);

      setMetaSummaryResponse({ id: docRef.id, summary: response.data });
      setShowMetaSummaryModal(true);
      toast.success('Meta summary generated and saved!');
      setSelectedThemes([]);
    } catch (error) {
      console.error('Error generating meta summary:', error);
      toast.error('Failed to generate meta summary.');
      setMetaSummaryResponse('Error generating meta summary. Please try again later.');
    } finally {
      setIsGeneratingMetaSummary(false);
    }
  };

  useEffect(() => {
    const loadThemes = async () => {
      if (!user) return;
      try {
        setLoading(true);
        setError(null);
        const fetchedThemes = await getCompletedThemes(user.uid);
        const themesNeedingTitles = fetchedThemes.filter(theme => !theme.themeTitle);
        if (themesNeedingTitles.length > 0) {
          const titles = await Promise.all(
            themesNeedingTitles.map(async theme => {
              const themeDoc = await getThemeById(theme.themeId);
              return {
                id: theme.id,
                title: themeDoc?.title || 'Theme'
              };
            })
          );

          // Update themes with fetched titles
          const updatedThemes = fetchedThemes.map(theme => {
            const titleInfo = titles.find(t => t.id === theme.id);
            if (titleInfo) {
              return {
                ...theme,
                themeTitle: titleInfo.title,
                summaryTitle: theme.themeType === 'experience_summary' 
                  ? 'Year In Review Summary' 
                  : `${titleInfo.title} Summary`
              };
            }
            return theme;
          });

          setThemes(updatedThemes);
        } else {
          setThemes(fetchedThemes);
        }
      } catch (error) {
        console.error('Error loading themes:', error);
        setError('Failed to load your completed themes. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    loadThemes();
  }, [user]);

  // Add effect to persist tab changes
  useEffect(() => {
    localStorage.setItem('dashboardTab', activeTab);
    // Update URL with active tab
    const url = new URL(window.location.href);
    url.searchParams.set('tab', activeTab);
    window.history.replaceState({}, '', url.toString());
  }, [activeTab]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-200 border-t-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading your completed themes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4">
            <select
              value={experienceFilter}
              onChange={(e) => setExperienceFilter(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
            >
              <option value="all">All Experiences</option>
              <option value="year-in-review">Year in Review</option>
              <option value="shared-experiences">Shared Reflections</option>
            </select>
            <button
              onClick={() => setShowOnlyExperienceSummaries(!showOnlyExperienceSummaries)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                showOnlyExperienceSummaries
                  ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {showOnlyExperienceSummaries ? 'Show All' : 'Show Experience Summaries'}
            </button>
          </div>

          {/* Selection Actions */}
          {selectedThemes.length > 0 && themes.length > 0 && (
            <div className="flex space-x-4">
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
              >
                <Trash2 className="w-4 h-4" />
                <span>Delete Selected ({selectedThemes.length})</span>
              </button>
              {selectedThemes.length > 1 && (
                <button
                  onClick={() => setShowTitleModal(true)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  disabled={isGeneratingMetaSummary}
                >
                  {isGeneratingMetaSummary ? 'Generating...' : 'Create Meta Summary'}
                </button>
              )}
            </div>
          )}
        </div>

        {/* Theme list */}
        <div className="space-y-6">
          {themes
            .filter(theme => {
              if (showOnlyExperienceSummaries) {
                return theme.themeType === 'experience_summary';
              }
              if (experienceFilter !== 'all') {
                if (experienceFilter === 'shared-experiences') {
                  return theme.experienceId === 'shared-experiences' || theme.experienceId === 'FkfQnHvmsEdjfME9n3sS';
                }
                return theme.experienceId === experienceFilter;
              }
              return true;
            })
            .map((theme) => (
              <motion.div
                key={theme.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`bg-white rounded-xl shadow-sm overflow-hidden cursor-pointer transition-all ${
                  selectedThemes.includes(theme.id) ? 'ring-2 ring-blue-500 ring-offset-2' : ''
                }`}
                onClick={() => handleThemeSelect(theme.id)}
              >
                <div className="px-6 py-4 bg-gradient-to-r from-indigo-50 to-blue-50">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="flex items-center gap-2 mb-1">
                        <h3 className="text-xl font-semibold text-indigo-900">
                          {theme.theme_name || theme.themeTitle || theme.summaryTitle || 'Theme Summary'}
                          {theme.createdFor && (
                            <span className="ml-2 text-base text-indigo-600">
                              (Created for {theme.createdFor})
                            </span>
                          )}
                        </h3>
                        {theme.themeType === 'experience_summary' && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                            Experience Summary
                          </span>
                        )}
                      </div>
                      <div className="text-sm text-gray-600 mt-2">
                        <p>{theme.completedAt && `Completed on ${new Date(theme.completedAt).toLocaleDateString()}`}</p>
                        <p className="text-xs text-gray-400 mt-1">Theme ID: {theme.id}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShare(theme);
                        }}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                        title="Share Summary"
                      >
                        <Share2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewSummary(theme);
                        }}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                      >
                        <FileText className="w-5 h-5" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadPDF(theme);
                        }}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                      >
                        <Download className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </div>

      {/* Modals */}
      {showTitleModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium mb-4">Meta Summary Title</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={metaSummaryTitle}
                onChange={(e) => setMetaSummaryTitle(e.target.value)}
                placeholder="Enter a title (optional)"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <textarea
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
                placeholder="Add questions to add to the summary generation process"
                className="w-full p-2 border border-gray-300 rounded-lg h-24 resize-none"
              />
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setShowTitleModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleTitleSubmit}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Generate Summary
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-lg w-96">
            <p className="text-lg font-medium mb-4">Are you sure you want to delete the selected themes?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleDeleteSelectedThemes}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedThemeId && user && (
        <ThemeSummaryViewer
          isOpen={showSummaryViewer}
          onClose={() => {
            setShowSummaryViewer(false);
            setSelectedThemeId(null);
          }}
          completedThemeId={selectedThemeId}
          userId={user.uid}
        />
      )}
      
      {showPDFModal && selectedThemeForPDF && (
        <PDFGenerator
          isOpen={showPDFModal}
          onClose={() => {
            setShowPDFModal(false);
            setSelectedThemeForPDF(null);
          }}
          title={selectedThemeForPDF.themeType === 'experience_summary' ? 'Year In Review' : (selectedThemeForPDF.themeTitle || selectedThemeForPDF.summaryTitle || selectedThemeForPDF.theme_name || 'Theme')}
          content={selectedThemeForPDF.summary}
          createdFor={selectedThemeForPDF.createdFor}
          responses={selectedThemeForPDF.responses?.map(r => ({
            question: r.question,
            content: r.content
          }))}
        />
      )}

      {showMetaSummaryModal && metaSummaryResponse && (
        <ThemeSummaryViewer
          isOpen={showMetaSummaryModal}
          onClose={async () => {
            setShowMetaSummaryModal(false);
            setMetaSummaryResponse(null);
            if (user) {
              const updatedThemes = await getCompletedThemes(user.uid);
              setThemes(updatedThemes);
            }
          }}
          completedThemeId={metaSummaryResponse.id}
          userId={user.uid}
        />
      )}
    </div>
  );
}