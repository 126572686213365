import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { PurchaseType, Duration } from '../enums/PurchaseEnums';
import { useAuth } from '../contexts/AuthContext';
import BuyIndividualCard from './BuyIndividualCard';
import BuyBulkCard from './BuyBulkCard';
import BuyEnterpriseCard from './BuyEnterpriseCard';
import { PRICING, calculateMonthlyPrice, calculateAnnualPrice } from '../lib/dal/subscriptions';

interface CheckoutData {
  duration: Duration;
  purchaseType: PurchaseType;
  quantity: number;
  successPath: string;
}


//TODO:  What if the user is already logged in?
// -- if they are free, we should show upgrade language and options.
// -- for groups -- what are they buying? are they adding more seats to an existing group? are they creating a new group?


//TODO: How should we prevent a personal member from purchasing with an existing email?
//  -- for individual purchases, we should check if the email is already in the system.  If it is, we should prompt the user to login and upgrade.
//  -- we probably need to add an email field to the Individual purchase form.

// GROUP Purchase
// if they are not logged in, we assume it's a new group purchase. 
// if they are logged in, we should offer options to add seats to an existing group or create a new group.


export default function Buy() {
  const [duration, setDuration] = useState<Duration>(Duration.ANNUAL);
  const [purchaseType, setPurchaseType] = useState<PurchaseType>(PurchaseType.INDIVIDUAL);
  const [quantity, setQuantity] = useState(5);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const { user } = useAuth();

  const handleCheckout = (pType: PurchaseType, email?: string) => {
    setPurchaseType(pType);

    const checkoutData: CheckoutData = {
      duration,
      purchaseType: pType,
      quantity: pType === PurchaseType.GROUP ? quantity : 1,
      successPath: 'Welcome'
    };
    console.log('Checkout data:', checkoutData);
    handlePayment(checkoutData, email);
  };

  const handlePayment = async (checkoutData: CheckoutData, email?: string) => {

    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user?.uid || '', 
          email: email || '',
          quantity: checkoutData.quantity, 
          duration: checkoutData.duration, 
          purchaseType: checkoutData.purchaseType, 
          successPath: checkoutData.successPath }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      window.location.href = data.url;
  
    } catch (err: any) {
      console.error('Error:', err.message);
      const errorMessage = 'There was a problem with the payment service. Please try again.';
      toast.error(errorMessage);
    }
}


const monthlyGroupPrice = calculateMonthlyPrice(PurchaseType.GROUP);
const monthlyIndividualPrice = calculateMonthlyPrice(PurchaseType.INDIVIDUAL);
const annualGroupPrice = calculateAnnualPrice(PurchaseType.GROUP);
const annualIndividualPrice = calculateAnnualPrice(PurchaseType.INDIVIDUAL);


  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-indigo-900 mb-4">Choose Your Plan</h1>
          <p className="text-xl text-indigo-700">Begin your journey of self-discovery and growth</p>
        </div>

        <div className="flex justify-center items-center gap-4 mb-8">
          <div className="bg-white rounded-lg p-2 inline-flex shadow-sm">
            <button
              onClick={() => setDuration(Duration.MONTHLY)}
              className={`px-4 py-2 rounded-md transition-colors ${
                duration === Duration.MONTHLY ? 'bg-indigo-100 text-indigo-900' : 'text-gray-600 hover:text-indigo-700'
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setDuration(Duration.ANNUAL)}
              className={`px-4 py-2 rounded-md transition-colors ${
                duration === Duration.ANNUAL ? 'bg-indigo-100 text-indigo-900' : 'text-gray-600 hover:text-indigo-700'
              }`}
            >
              Annual
            </button>
          </div>
          {duration === Duration.ANNUAL && (
            <span className="inline-block bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-1 rounded">
              {(PRICING.ANNUAL_DISCOUNT * 100)}% discount
            </span>
          )}
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <BuyIndividualCard
            duration={duration}
            purchaseType={purchaseType}
            setPurchaseType={setPurchaseType}
            onCheckout={handleCheckout}
            monthlyPrice={monthlyIndividualPrice}
            annualPrice={annualIndividualPrice}
          />

          <BuyBulkCard
            duration={duration}
            purchaseType={purchaseType}
            setPurchaseType={setPurchaseType}
            onCheckout={handleCheckout}
            quantity={quantity}
            setQuantity={setQuantity}
            monthlyPrice={monthlyGroupPrice}
            annualPrice={annualGroupPrice}
          />

          <BuyEnterpriseCard />
        </div>
        
      </div>
    </div>
  );
}
