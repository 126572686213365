import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { isUserAdmin } from '../../../lib/firebase';
import { Subscription, SubscriptionService, FirebaseSubscriptionRepository } from '../../../lib/dal/subscriptions';
import { Edit2, UserPlus, Copy } from 'lucide-react';
import { UserService, FirebaseUserRepository } from '../../../lib/dal/users';
import toast from 'react-hot-toast';
import SubscriptionModal from './SubscriptionModal';
import DarkDashboard from './Dashboard';
import SubscriptionDashboard from './SubscriptionDashboard';
import SubscriptionTable from './SubscriptionTable';
import AddSubscriptionModal from './AddSubscriptionModal';

const BATCH_SIZE = 30; // Firebase IN clause limit

export default function Subscriptions() {
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
  const [adminMap, setAdminMap] = useState<{[key: string]: { firstName?: string; lastName?: string }}>({});
  const [showAddModal, setShowAddModal] = useState(false);

  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
  const userService = new UserService(new FirebaseUserRepository());

  useEffect(() => {
    if (user?.uid) {
      fetchSubscriptions();
    }
  }, [user?.uid]);

  const fetchSubscriptions = async () => {
    try {
      if (!user) throw new Error('No authenticated user');
      const isAdmin = await isUserAdmin(user.uid);
      if (!isAdmin) {
        setError('You do not have admin privileges.');
        setLoading(false);
        return;
      }

      const data = await subscriptionService.getAllSubscriptions();
      if (data) {
        setSubscriptions(data);
        
        // Get unique admin IDs
        const uniqueAdminIds = Array.from(new Set(data.map(sub => sub.admin_id).filter(Boolean)));
        
        // Process admin IDs in batches of 30
        const adminData = {};
        for (let i = 0; i < uniqueAdminIds.length; i += BATCH_SIZE) {
          const batch = uniqueAdminIds.slice(i, i + BATCH_SIZE);
          try {
            const batchAdmins = await userService.getUsers(batch as string[]);
            batchAdmins.forEach(user => {
              adminData[user.id] = { firstName: user.firstName, lastName: user.lastName };
            });
          } catch (err) {
            console.error(`Error fetching batch ${i}-${i + BATCH_SIZE}:`, err);
          }
        }
        
        setAdminMap(adminData);
      }
    } catch (err) {
      setError('Failed to load subscriptions');
      console.error('Subscription fetch error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (sub: Subscription) => {
    setSelectedSubscription(sub);
    setShowModal(true);
  };

  const handleAdd = () => {
    setSelectedSubscription(null);
    setShowModal(true);
  };

  const handleCopy = (id: string) => {
    console.log('Copying ID:', id);
    navigator.clipboard.writeText(id);
    toast.success('Copied subscription ID to clipboard');
  };

  const handleAddSubscription = async (newSubscription: Partial<Subscription>) => {
    try {
      await subscriptionService.createSubscription(newSubscription);
      toast.success('Subscription created successfully');
      fetchSubscriptions();
    } catch (error) {
      console.error('Error creating subscription:', error);
      toast.error('Failed to create subscription');
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <SubscriptionDashboard subscriptions={subscriptions} />

      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Subscriptions</h2>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg"
          >
            <UserPlus className="w-4 h-4" />
            <span>Add Subscription</span>
          </button>
        </div>
      </div>

      <SubscriptionTable
        subscriptions={subscriptions}
        adminMap={adminMap}
        onCopy={handleCopy}
        onEdit={handleEdit}
      />

      <SubscriptionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSaved={fetchSubscriptions}
        subscription={selectedSubscription}
      />

      <AddSubscriptionModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onSave={handleAddSubscription}
      />
    </div>
  );
}