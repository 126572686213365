import { collection, query, where, orderBy, addDoc, updateDoc, deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from './config';
import { Pack, PackMessage } from './types';
import { createNotification } from './notifications';

export async function createPack(pack: Omit<Pack, 'id'>): Promise<string> {
  const docRef = await addDoc(collection(db, 'packs'), pack);
  return docRef.id;
}

export async function getUserPacks(userId: string): Promise<Pack[]> {
  const q = query(
    collection(db, 'packs'),
    where('members', 'array-contains', userId)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Pack));
}

export async function deletePack(packId: string): Promise<void> {
  await deleteDoc(doc(db, 'packs', packId));
}

export async function updatePack(packId: string, data: Partial<Pack>): Promise<void> {
  await updateDoc(doc(db, 'packs', packId), {
    ...data,
    updatedAt: new Date().toISOString()
  });
}

export async function createPackMessage(packId: string, message: Partial<PackMessage>): Promise<string> {
  const docRef = await addDoc(collection(db, 'packMessages'), { 
    ...message, 
    packId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });
  
  // Update pack's lastMessageAt
  await updateDoc(doc(db, 'packs', packId), {
    lastMessageAt: new Date().toISOString()
  });
  
  return docRef.id;
}

export async function getPackMessages(packId: string): Promise<PackMessage[]> {
  const q = query(
    collection(db, 'packMessages'),
    where('packId', '==', packId),
    orderBy('createdAt', 'desc')
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as PackMessage));
}

// Add function to fetch pack by ID
export async function getPackById(packId: string): Promise<Pack | null> {
  const docRef = doc(db, 'packs', packId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() } as Pack;
  }
  return null;
}

export const deletePackMessage = async (packId: string, messageId: string) => {
  try {
    const messageRef = doc(db, 'packMessages', messageId); // Corrected path
    await deleteDoc(messageRef);
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
};