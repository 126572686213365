import React, { useState } from 'react';
import { Subscription } from '../../../lib/dal/subscriptions';
import { Copy, Edit2, ArrowUpDown, Search } from 'lucide-react';

export default function SubscriptionTable({
  subscriptions,
  adminMap,
  onCopy,
  onEdit,
}: {
  subscriptions: Subscription[];
  adminMap: { [key: string]: { firstName?: string; lastName?: string } };
  onCopy: (id: string) => void;
  onEdit: (sub: Subscription) => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterEnv, setFilterEnv] = useState('all');
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Subscription;
    direction: 'asc' | 'desc';
  }>({ key: 'start_date', direction: 'desc' });

  const handleSort = (key: keyof Subscription) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredAndSortedSubscriptions = subscriptions
    .filter(sub => {
      const matchesSearch = 
        `${sub.code} ${sub.subscription_source} ${adminMap[sub.admin_id]?.firstName || ''} ${adminMap[sub.admin_id]?.lastName || ''}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      
      const matchesType = 
        filterType === 'all' || 
        (filterType === 'individual' && (sub.paid_seats + sub.certified_seats) === 1) ||
        (filterType === 'bulk' && (sub.paid_seats + sub.certified_seats) > 1);
      
      const matchesEnv = 
        filterEnv === 'all' || 
        sub.env === filterEnv;
      
      return matchesSearch && matchesType && matchesEnv;
    })
    .sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      return sortConfig.direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });

  return (
    <div>
      <div className="p-6 border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search subscriptions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Types</option>
            <option value="individual">Individual</option>
            <option value="bulk">Bulk</option>
          </select>
          
          <select
            value={filterEnv}
            onChange={(e) => setFilterEnv(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="all">All Environments</option>
            <option value="production">Production</option>
            <option value="preview">Preivew</option>
            <option value="development">Development</option>
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th onClick={() => handleSort('code')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100">
                <div className="flex items-center space-x-1">
                  <span>Code</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Env</th>
              <th onClick={() => handleSort('start_date')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100">
                <div className="flex items-center space-x-1">
                  <span>Start / End Date</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th onClick={() => handleSort('subscription_source')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100">
                <div className="flex items-center space-x-1">
                  <span>Subscription Source</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th onClick={() => handleSort('admin_id')} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100">
                <div className="flex items-center space-x-1">
                  <span>Admin</span>
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th className="px-6 py-3" />
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredAndSortedSubscriptions.map((sub) => (
              <tr key={sub.id}>
                <td className="px-6 py-4 whitespace-nowrap">{(sub.paid_seats + sub.certified_seats) === 1 ? '' : sub.code}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {(sub.paid_seats + sub.certified_seats) === 1 ? 'Individual' : 'Bulk ' + (sub.paid_seats + sub.certified_seats) }
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{sub.env}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {new Date(sub.start_date).toLocaleDateString()} - {new Date(sub.end_date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{sub.subscription_source}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {adminMap[sub.admin_id]
                    ? `${adminMap[sub.admin_id].firstName || ''} ${adminMap[sub.admin_id].lastName || ''}`
                    : sub.admin_id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => onCopy(sub.id)}
                      className="p-1 text-gray-600 hover:text-gray-900"
                      title="Copy subscription ID"
                    >
                      <Copy className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => onEdit(sub)}
                      className="p-1 text-blue-600 hover:text-blue-900"
                      title="Edit subscription"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}