import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { X, Download, Share2, FileText } from 'lucide-react';
import { jsPDF } from 'jspdf';
import { CompletedExperience, getCompletedExperiences } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import ReactMarkdown from 'react-markdown';
import { stages } from '../data/stages';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import PacksTab from '../components/Packs/PacksTab';
import { ThemeSummaryViewer } from '../components/shared';
import { toast } from 'react-hot-toast';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface DashboardProps {
  onClose: () => void;
}

export default function Dashboard({ onClose }: DashboardProps) {
  const { user } = useAuth();
  const [experiences, setExperiences] = React.useState<CompletedExperience[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedExperience, setSelectedExperience] = React.useState<CompletedExperience | null>(null);
  const [selectedThemeId, setSelectedThemeId] = React.useState<string | null>(null);
  const [showSummaryViewer, setShowSummaryViewer] = React.useState(false);
  const [showPDFModal, setShowPDFModal] = React.useState(false);
  const [selectedThemeForPDF, setSelectedThemeForPDF] = React.useState<CompletedTheme | null>(null);
  const [activeTab, setActiveTab] = React.useState(() => {
    const savedTab = localStorage.getItem('dashboardTab') || 'activity';
    const urlParams = new URLSearchParams(window.location.search);
    const urlTab = urlParams.get('tab');
    const hasSelectedPack = localStorage.getItem('selectedPackId');
    
    // Priority:
    // 1. URL tab parameter
    // 2. Selected pack (only if no tab specified)
    // 3. Saved tab preference
    const initialTab = urlTab || (hasSelectedPack ? 'packs' : savedTab);
    
    return initialTab;
  });
  const [showOnlyExperienceSummaries, setShowOnlyExperienceSummaries] = React.useState(false);
  const [experienceFilter, setExperienceFilter] = React.useState('all');
  const [showSummaryModal, setShowSummaryModal] = React.useState(false);

  React.useEffect(() => {
    const loadExperiences = async () => {
      if (!user) return;
      try {
        // Force Firestore to get fresh data by using getDocsFromServer
        const completionsRef = collection(db, 'completedThemes');
        const q = query(
          completionsRef,
          where('userId', '==', user.uid),
          orderBy('completedAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const completedThemes = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CompletedTheme[];

        // Group themes by experienceId
        const experienceMap = new Map<string, CompletedExperience>();
        
        // First process experience summaries
        completedThemes
          .filter(theme => theme.themeType === 'experience_summary')
          .forEach(theme => {
            if (!theme.experienceId) return;
            
            experienceMap.set(theme.experienceId, {
              id: theme.experienceId,
              userId: theme.userId,
              userName: theme.userName,
              experienceName: theme.experienceName || theme.themeTitle || 'Untitled Experience',
              completedAt: theme.completedAt,
              summary: theme.summary,
              themes: [{
                themeId: theme.id,
                themeName: theme.themeTitle || theme.summaryTitle || 'Experience Summary',
                summary: theme.summary,
                themeType: theme.themeType,
                experienceId: theme.experienceId
              }]
            });
          });

        // Then process regular themes
        completedThemes
          .filter(theme => theme.themeType === 'theme')
          .forEach(theme => {
            if (!theme.experienceId) return;
            
            if (!experienceMap.has(theme.experienceId)) {
              experienceMap.set(theme.experienceId, {
                id: theme.experienceId,
                userId: theme.userId,
                userName: theme.userName,
                experienceName: theme.experienceName || 'Untitled Experience',
                completedAt: theme.completedAt,
                summary: theme.summary,
                themes: []
              });
            }
            
            const experience = experienceMap.get(theme.experienceId)!;
            experience.themes.push({
              themeId: theme.id,
              themeName: theme.themeTitle || 'Untitled Theme',
              summary: theme.summary,
              themeType: theme.themeType,
              experienceId: theme.experienceId
            });
          });

        setExperiences(Array.from(experienceMap.values()));
      } catch (error) {
        console.error('Error loading completed experiences:', error);
      } finally {
        setLoading(false);
      }
    };

    loadExperiences();
  }, [user]);

  // Add effect to persist tab changes
  useEffect(() => {
    localStorage.setItem('dashboardTab', activeTab);
    // Update URL with active tab
    const url = new URL(window.location.href);
    url.searchParams.set('tab', activeTab);
    window.history.replaceState({}, '', url.toString());
  }, [activeTab]);

  // Add effect to handle pack selection
  useEffect(() => {
    const hasSelectedPack = localStorage.getItem('selectedPackId');
    const urlParams = new URLSearchParams(window.location.search);
    const urlTab = urlParams.get('tab');
    
    // Only switch to packs tab if there's no explicit tab in the URL
    if (hasSelectedPack && !urlTab) {
      setActiveTab('packs');
    }
  }, []);

  const downloadPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    let yPos = margin;

    // Title
    doc.setFontSize(24);
    doc.setTextColor(75, 85, 99);
    doc.text('Your Journey Summary', pageWidth / 2, yPos, { align: 'center' });
    yPos += 20;

    experiences.forEach((experience) => {
      // Experience Title
      doc.setFontSize(18);
      doc.setTextColor(31, 41, 55);
      doc.text(experience.experienceName, margin, yPos);
      yPos += 10;

      experience.themes.forEach((theme) => {
        // Theme Title
        doc.setFontSize(14);
        doc.setTextColor(75, 85, 99);
        doc.text(theme.themeName, margin, yPos);
        yPos += 7;

        // Theme Summary
        doc.setFontSize(12);
        doc.setTextColor(31, 41, 55);
        const lines = doc.splitTextToSize(theme.summary, pageWidth - (margin * 2));
        doc.text(lines, margin, yPos);
        yPos += (lines.length * 7) + 10;

        // Add new page if needed
        if (yPos > doc.internal.pageSize.getHeight() - 20) {
          doc.addPage();
          yPos = margin;
        }
      });

      yPos += 10;
    });

    doc.save('journey-summary.pdf');
  };

  const handleViewSummary = async (themeId: string) => {
    try {
      setSelectedThemeId(themeId);
      setShowSummaryViewer(true);
    } catch (error) {
      console.error('Error viewing summary:', error);
      toast.error('Failed to load theme summary');
    }
  };

  const handleDownloadPDF = (theme: CompletedTheme) => {
    setSelectedThemeForPDF(theme);
    setShowPDFModal(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Navigation Tabs */}
        <div className="border-b border-gray-200 mb-8">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('activity')}
              className={`pb-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'activity'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              My Activity
            </button>
            <button
              onClick={() => setActiveTab('packs')}
              className={`pb-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'packs'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              My Packs
            </button>
          </nav>
        </div>

        {/* Tab Content */}
        {activeTab === 'packs' ? (
          <PacksTab />
        ) : (
          <div>
            {experiences.length === 0 ? (
              <div className="text-center py-12 bg-white rounded-lg shadow-sm">
                <p className="text-gray-600">No completed experiences yet. Start your journey!</p>
              </div>
            ) : (
              <div className="space-y-6">
                {experiences
                  .filter(experience => {
                    if (showOnlyExperienceSummaries) {
                      return experience.themes.some(theme => theme.themeType === 'experience_summary');
                    }
                    if (experienceFilter !== 'all') {
                      if (experienceFilter === 'shared-experiences') {
                        return experience.themes.some(theme => theme.experienceId === 'shared-experiences' || theme.experienceId === 'FkfQnHvmsEdjfME9n3sS');
                      }
                      return experience.themes.some(theme => theme.experienceId === experienceFilter);
                    }
                    return true;
                  })
                  .map((experience) => (
                    <motion.div
                      key={experience.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`bg-white rounded-xl shadow-sm overflow-hidden ${
                        experience.themes.some(theme => theme.themeType === 'experience_summary') ? 'ring-2 ring-indigo-500' : ''
                      }`}
                    >
                      <div className={`px-6 py-4 ${
                        experience.themes.some(theme => theme.themeType === 'experience_summary') 
                          ? 'bg-gradient-to-r from-indigo-100 to-purple-100'
                          : 'bg-gradient-to-r from-indigo-50 to-blue-50'
                      }`}>
                        <div className="flex justify-between items-center">
                          <div>
                            <h3 className="text-xl font-semibold text-indigo-900">
                              {experience.experienceName}
                            </h3>
                            {experience.themes.some(theme => theme.themeType === 'experience_summary') && (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                Experience Summary
                              </span>
                            )}
                          </div>
                          <div className="flex space-x-2">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                experience.themes.forEach(theme => handleViewSummary(theme.themeId));
                              }}
                              className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                              title="View Summary"
                            >
                              <FileText className="w-5 h-5" />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                experience.themes.forEach(theme => handleDownloadPDF(theme));
                              }}
                              className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                              title="Download PDF"
                            >
                              <Download className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                        <p className="text-sm text-gray-600 mt-1">
                          {experience.completedAt && `Completed on ${new Date(experience.completedAt).toLocaleDateString()}`}
                        </p>
                      </div>
                    </motion.div>
                  ))}
              </div>
            )}

            {selectedThemeId && user && (
              <ThemeSummaryViewer
                isOpen={showSummaryViewer || showSummaryModal}
                onClose={() => {
                  setShowSummaryViewer(false);
                  setShowSummaryModal(false);
                  setSelectedThemeId(null);
                }}
                completedThemeId={selectedThemeId}
                userId={user.uid}
              />
            )}
            
            {showPDFModal && selectedThemeForPDF && (
              <PDFGenerator
                isOpen={showPDFModal}
                onClose={() => {
                  setShowPDFModal(false);
                  setSelectedThemeForPDF(null);
                }}
                title={selectedThemeForPDF.themeType === 'experience_summary' ? 'Year In Review' : (selectedThemeForPDF.themeTitle || selectedThemeForPDF.summaryTitle || 'Theme')}
                content={selectedThemeForPDF.summary}
                createdFor={selectedThemeForPDF.createdFor}
                responses={selectedThemeForPDF.responses?.map(r => ({
                  question: r.question,
                  content: r.content
                }))}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}