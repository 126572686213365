import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Download } from 'lucide-react';
import { PDFGenerator } from './PDFGenerator';

export default function PublicShare() {
  const { shareId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [share, setShare] = useState<any>(null);
  const [showPDFModal, setShowPDFModal] = useState(false);

  useEffect(() => {
    const loadShare = async () => {
      if (!shareId) return;
      
      try {
        const shareDoc = await getDoc(doc(db, 'publicShares', shareId));
        if (!shareDoc.exists()) {
          setError('This shared summary is no longer available');
          return;
        }

        const data = shareDoc.data();
        // Check if share has expired
        if (data.expiresAt && new Date(data.expiresAt) < new Date()) {
          setError('This shared summary has expired');
          return;
        }

        setShare(data);
      } catch (error) {
        console.error('Error loading shared summary:', error);
        setError('Unable to load shared summary');
      } finally {
        setLoading(false);
      }
    };

    loadShare();
  }, [shareId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">{error}</h2>
          <p className="text-gray-600">
            Visit <a href="/" className="text-purple-600 hover:text-purple-700">Finding Blueberries</a> to create your own reflections.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="sticky top-0 bg-white border-b px-6 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-bold text-indigo-900">Theme Summary</h1>
            <button
              onClick={() => setShowPDFModal(true)}
              className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
              title="Download PDF"
            >
              <Download className="w-5 h-5" />
            </button>
          </div>

          <div className="px-6 py-6">
            <div className="prose prose-indigo max-w-none [&>ul]:list-none [&>ul]:pl-0 [&>ul>li]:relative [&>ul>li]:pl-6 [&>ul>li]:mb-2 [&>ul>li]:before:content-['•'] [&>ul>li]:before:absolute [&>ul>li]:before:left-0 [&>ul>li]:before:text-indigo-500">
              <ReactMarkdown
                components={{
                  h1: ({ children }) => (
                    <h1 className="text-3xl font-bold text-indigo-900 mb-6">{children}</h1>
                  ),
                  h2: ({ children }) => (
                    <h2 className="text-2xl font-semibold text-indigo-800 mt-8 mb-4">{children}</h2>
                  ),
                  p: ({ children }) => (
                    <p className="text-indigo-700 mb-4">{children}</p>
                  ),
                  ul: ({ children }) => (
                    <ul className="list-disc list-inside space-y-2 text-indigo-700 mb-4">{children}</ul>
                  ),
                  li: ({ children }) => (
                    <li className="text-indigo-700">{children}</li>
                  ),
                }}
              >
                {share?.summary || ''}
              </ReactMarkdown>
            </div>
          </div>

          <div className="px-6 py-4 bg-gray-50 border-t">
            <p className="text-sm text-gray-500 text-center">
              This summary was shared from{' '}
              <a href="/" className="text-indigo-600 hover:text-indigo-700">
                Finding Blueberries
              </a>
            </p>
          </div>
        </motion.div>
      </div>

      {share && showPDFModal && (
        <PDFGenerator
          isOpen={showPDFModal}
          onClose={() => setShowPDFModal(false)}
          title="Theme Summary"
          content={share.summary}
          responses={[]}
        />
      )}
    </div>
  );
}