import React, { useState, useEffect, useRef } from 'react';
import { Subscription, SubscriptionService, FirebaseSubscriptionRepository, PricePlan } from '../../../lib/dal/subscriptions';

const initialFormState: Partial<Subscription> = {
  subscription_source: undefined,
  external_customer_id: '',
  external_subscription_id: '',
  price_plan: { planID: "001", description: "", price: 0, planName: "internal", duration: "annual" },
  start_date: '',
  end_date: '',
  paid_seats: 0,
  certified_seats: 0,
  members: [],
  renewal_status: undefined,
  history: [],
  code: ''
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSaved: () => void;
  subscription?: Subscription | null;
}

export default function SubscriptionModal({ isOpen, onClose, onSaved, subscription }: Props) {
  const [form, setForm] = useState<Partial<Subscription>>(initialFormState);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
  const firstErrorRef = useRef<HTMLInputElement | HTMLSelectElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setForm(subscription || initialFormState);
      setErrors({});
    }
  }, [isOpen, subscription]);

  const handleSubmit = async () => {
    const newErrors: { [key: string]: boolean } = {};
    if (!form.subscription_source) newErrors.subscription_source = true;
    if (!form.start_date) newErrors.start_date = true;
    if (!form.end_date) newErrors.end_date = true;
    if (!form.renewal_status) newErrors.renewal_status = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      if (firstErrorRef.current) {
        firstErrorRef.current.focus();
      }
      return;
    }

    try {
      if (subscription) {
        // Update existing subscription
        const updateSub = { id: subscription.id, price_plan: form.price_plan, ...form } as Subscription;
        await subscriptionService.updateSubscription(updateSub);
      } else {

        const createSub = { price_plan: form.price_plan as PricePlan, ...form } as Subscription; 
        // Create new subscription
        await subscriptionService.createSubscription(createSub);
      }

      onSaved();
      onClose();
    } catch (err) {
      console.error('Error saving subscription:', err);
      alert('Failed to save subscription');
    }
  };

  const RequiredLabel = ({ children }: { children: React.ReactNode }) => (
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {children} <span className="text-red-500">*</span>
    </label>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div className="absolute inset-0 bg-gray-900/50 backdrop-blur-sm" onClick={onClose} />
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200">
            <h2 className="text-2xl font-semibold text-gray-800">
              {subscription ? 'Edit Subscription' : 'Add Subscription'}
            </h2>
          </div>
          
          <div className="p-6 grid gap-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <h3 className="font-medium text-gray-700">Basic Information</h3>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">External Subscription ID</label>
                  <input 
                    type="text"
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.external_subscription_id ? 'border-red-500' : ''}`}
                    value={form.external_subscription_id || ''} 
                    onChange={e => setForm({ ...form, external_subscription_id: e.target.value })}
                  />
                </div>
                
                <div>
                  <RequiredLabel>Subscription Source</RequiredLabel>
                  <select
                    ref={el => errors.subscription_source && !firstErrorRef.current ? firstErrorRef.current = el : null}
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.subscription_source ? 'border-red-500' : ''}`}
                    value={form.subscription_source || ''}
                    onChange={e => setForm({ ...form, subscription_source: e.target.value as any })}
                  >
                    <option value="">Select Source</option>
                    <option value="stripe">Stripe</option>
                    <option value="certification">Certification</option>
                  </select>
                </div>
                
                <div>
                  <RequiredLabel>External Customer ID</RequiredLabel>
                  <input
                    type="text"
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.external_customer_id ? 'border-red-500' : ''}`}
                    value={form.external_customer_id || ''}
                    onChange={e => setForm({ ...form, external_customer_id: e.target.value })}
                  />
                </div>
              </div>
              
              <div className="space-y-4">
                <h3 className="font-medium text-gray-700">Subscription Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <RequiredLabel>Start Date</RequiredLabel>
                    <input 
                      ref={el => errors.start_date && !firstErrorRef.current ? firstErrorRef.current = el : null}
                      type="date"
                      className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.start_date ? 'border-red-500' : ''}`}
                      value={form.start_date || ''} 
                      onChange={e => setForm({ ...form, start_date: e.target.value })}
                    />
                  </div>
                  <div>
                    <RequiredLabel>End Date</RequiredLabel>
                    <input 
                      ref={el => errors.end_date && !firstErrorRef.current ? firstErrorRef.current = el : null}
                      type="date"
                      className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.end_date ? 'border-red-500' : ''}`}
                      value={form.end_date || ''} 
                      onChange={e => setForm({ ...form, end_date: e.target.value })}
                    />
                  </div>
                </div>
                
                <div>
                  <RequiredLabel>Paid Seats</RequiredLabel>
                  <input 
                    type="number"
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.paid_seats ? 'border-red-500' : ''}`}
                    value={form.paid_seats || 0} 
                    onChange={e => setForm({ ...form, paid_seats: Number(e.target.value) })}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Certified Seats</label>
                  <input 
                    type="number"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={form.certified_seats || 0} 
                    onChange={e => setForm({ ...form, certified_seats: Number(e.target.value) })}
                  />
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium text-gray-700">Advanced Settings</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="md:col-span-2">
                  <RequiredLabel>Price Plan (JSON)</RequiredLabel>
                  <textarea
                    rows={4}
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 font-mono text-sm ${errors.price_plan ? 'border-red-500' : ''}`}
                    value={form.price_plan ? JSON.stringify(form.price_plan, null, 2) : ''}        
                    onChange={e => {
                      try {
                        setForm({
                          ...form,
                          price_plan: JSON.parse(e.target.value)
                        });
                      } catch {}
                    }}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Admin ID</label>
                  <input 
                    type="text"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={form.admin_id || ''} 
                    onChange={e => setForm({ ...form, admin_id: e.target.value })}
                  />
                </div>
                <div>
                  <RequiredLabel>Renewal Status</RequiredLabel>
                  <select
                    ref={el => errors.renewal_status && !firstErrorRef.current ? firstErrorRef.current = el : null}
                    className={`w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${errors.renewal_status ? 'border-red-500' : ''}`}
                    value={form.renewal_status || ''}
                    onChange={e => setForm({ ...form, renewal_status: e.target.value as any })}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Code</label>
                  <input 
                    type="text"
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={form.code || ''} 
                    onChange={e => setForm({ ...form, code: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 bg-gray-50 px-6 py-4 border-t border-gray-200 flex justify-end space-x-3">
            <button 
              onClick={onClose}
              className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button 
              onClick={handleSubmit}
              className="px-4 py-2 rounded-md border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
