import { 
    collection, 
    query,
    Firestore, 
    CollectionReference, 
    DocumentData, 
    updateDoc,
    doc, 
    getDoc, 
    orderBy,
    getDocs,
    where,
    addDoc
} from 'firebase/firestore';
import { db } from '../firebase';
import { Duration, PurchaseType } from '../../enums/PurchaseEnums';

// Pricing constants
export const PRICING = {
    MONTHLY: {
        INDIVIDUAL: 10,
        GROUP: 8
    },
    ANNUAL_DISCOUNT: 0.17 // 17% discount
};

export const calculateMonthlyPrice = (type: PurchaseType): number => {
    return type === PurchaseType.INDIVIDUAL ? PRICING.MONTHLY.INDIVIDUAL : PRICING.MONTHLY.GROUP;
};

export const calculateAnnualPrice = (type: PurchaseType): number => {
    const monthlyPrice = calculateMonthlyPrice(type);
    return Math.round(monthlyPrice * 12 * (1 - PRICING.ANNUAL_DISCOUNT));
};

export const getPriceForDuration = (type: PurchaseType, duration: Duration): number => {
    return duration === Duration.MONTHLY ? calculateMonthlyPrice(type) : calculateAnnualPrice(type);
};

export enum SubscriptionSource {
    STRIPE = 'stripe',
    CERTIFICATION = 'certification',
    SPONSORED = 'sponsored'
}

export interface PricePlan {
    planID: string; //stripeSub.plan.id
    planName: string;
    price: number;  //stripeSub.plan.amount
    duration: Duration; //Duration
    description: string;  //`${quananity} seats at ${price} per ${duration}`
}

/*
  "plan": {
    "id": "price_1QlYkDE9yRcx9fqtxoT8XXBD",
    "object": "plan",
    "active": true,
    "aggregate_usage": null,
    "amount": 8000,
    "amount_decimal": "8000",
    "billing_scheme": "per_unit",
    "created": 1737909093,
    "currency": "usd",
    "interval": "month",
    "interval_count": 12,
    */

export enum RenewalStatus {
    CANCELED = 'canceled',
    ACTIVE = 'active'
}

export enum TransactionType {
    CHARGE = 'charge',
    REFUND = 'refund',
    ADMIN_ACTION = 'Admin Action',
    SUBSCRIPTION_CHANGE = 'Subscription Change'
}

export interface Transaction {
    date: string;
    type: TransactionType;
    amount: number;
    description: string;
    user_id: string;
}

export interface SubscriptionMember {
    user_id: string;
    team?: string;
}

export enum SubscriptionConstants {
    NOCODE = 'NOCODE'
}


export interface Subscription {
    id?: string;
    external_subscription_id?: string;
    subscription_source: SubscriptionSource;
    external_customer_id?: string;
    price_plan?: PricePlan;
    start_date: string;
    end_date: string;
    paid_seats: number;
    certified_seats: number;
    members: SubscriptionMember[];
    admin_id?: string;
    renewal_status: RenewalStatus;
    history: Transaction[];
    code: string;
    env?: string; // runtime environment
}

export interface CalculateSubscription extends Subscription {
    totalSeats: number;
    usedSeats: number;
}

const SUBSCRIPTIONTABLE = 'subscriptions';


export const generateSubscriptionCode = async (): Promise<string> => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    let isUnique = false;

    while (!isUnique) {
        result = '';
        for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
        }
        isUnique = await verifySubscriptionCodeUnique(result);
    }

    return result;
};

export const verifySubscriptionCodeUnique = async (code: string): Promise<boolean> => {
    const groupsRef = collection(db, SUBSCRIPTIONTABLE);
    const q = query(groupsRef, where('code', '==', code));
    const snapshot = await getDocs(q);
    return snapshot.empty;
};

const normalizeCode = (code: string): string => {
    return code.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
};

export interface ISubscriptionRepository {
    getSubscription: (subscriptionId: string) => Promise<Subscription>;
    getAdminSubscriptions: (userId: string) => Promise<Subscription[]>;
    getSubscriptionsByIds: (subscription_ids: string[]) => Promise<Subscription[]>;
    addMember: (subscriptionId: string, member: SubscriptionMember) => Promise<void>;
    removeMember: (subscriptionId: string, memberId: string) => Promise<void>;
    createSubscription: (subscription: Subscription) => Promise<Subscription>;
    updateSubscription: (subscription: Subscription) => Promise<Subscription>;
    deleteSubscription: (subscriptionId: string) => Promise<void>;
    getAllSubscriptions: () => Promise<Subscription[]>;
}

export interface ProrationCalculation {
  newSeatsCharge: number;
  existingSeatsDifferenceCharge: number;
  totalCharge: number;
  newEndDate: Date;
  description: string;
}

export function calculateProration(
  currentSeats: number,
  newSeats: number,
  currentEndDate: Date,
  duration: Duration,
  purchaseType: PurchaseType
): ProrationCalculation {
  const now = new Date();
  const daysRemaining = Math.ceil((currentEndDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
  
  // Calculate new end date based on duration
  const durationDays = duration === Duration.ANNUAL ? 365 : 30;
  const newEndDate = new Date(now.getTime() + durationDays * 24 * 60 * 60 * 1000);
  const extraDays = Math.ceil((newEndDate.getTime() - currentEndDate.getTime()) / (1000 * 3600 * 24));

  // Calculate charge for additional seats for full duration
  const seatDifference = newSeats - currentSeats;
  const pricePerSeat = getPriceForDuration(purchaseType, duration);
  const newSeatsCharge = seatDifference > 0 ? seatDifference * pricePerSeat : 0;

  // Calculate proration for existing seats
  const dailyRate = pricePerSeat / durationDays;
  const existingSeatsDifferenceCharge = currentSeats * dailyRate * extraDays;
  const description = `
    Extending your ${duration} plan
    • Current seats: ${currentSeats}
    • Extra days: ${extraDays}
    • Rate per seat per day: $${dailyRate.toFixed(2)}`.trim();

  return {
    newSeatsCharge,
    existingSeatsDifferenceCharge,
    totalCharge: newSeatsCharge + existingSeatsDifferenceCharge,
    newEndDate,
    description: description
  };
}

export class MockSubscriptionRepository implements ISubscriptionRepository {

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        console.log('getSubscription', subscriptionId);
        return Promise.resolve({} as Subscription);
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        console.log('getSubscriptions for user: ', userId);
        return Promise.resolve([]);
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        console.log('getSubscriptionsByIds', subscription_ids);
        return Promise.resolve([]);
    }

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        console.log(`createSubscription: ${JSON.stringify(subscription)}`);
        return Promise.resolve(subscription);
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        console.log('updateSubscription', subscription);
        return Promise.resolve(subscription);
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        console.log('deleteSubscription', subscriptionId);
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {
        console.log('addMember', subscriptionId, member.user_id);
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> {
        console.log('removeMember', subscriptionId, memberId);
    }

    async getAllSubscriptions(): Promise<Subscription[]> {
        console.log('getAllSubscriptions');
        return Promise.resolve([]);
    }
}


export class FirebaseSubscriptionRepository implements ISubscriptionRepository {
    private db: Firestore;
    private collection: CollectionReference<DocumentData>;

    constructor() {
        this.db = db;
        this.collection = collection(this.db, SUBSCRIPTIONTABLE);
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        const subscriptionDoc = await getDoc(doc(this.collection, subscriptionId));
        return subscriptionDoc.data() as Subscription;
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        try {
            const q = query(
              this.collection,
              where("admin_id", "==", userId)
            );
            
            const snapshot = await getDocs(q);
            
            return snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            } as Subscription));
          } catch (error) {
            console.error('Error fetching admin subscriptions:', error);
            throw error;
          }
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        try {
            const q = query(
                this.collection,
                where('__name__', 'in', subscription_ids)
            );
            const snapshot = await getDocs(q);
            console.log(`Found ${snapshot.size} subscriptions`);
            
            return snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            } as Subscription));
        } catch (error) {
            console.error('Error fetching subscriptions by ids:', error);
            throw error;
        }
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {

        let subscription: Subscription;
        try {
            subscription = await this.getSubscription(subscriptionId);
        } catch (error) {   
            console.error('Error adding member:', error);
            throw error;
        }

        if (!subscription) {
            throw new Error('Subscription not found');
        }
        if (!subscription.members) {
            subscription.members = [];
        }
        //is expired subscription
        if (new Date(subscription.end_date) < new Date()) {
            throw new Error('Subscription is expired');
        }
        const seatsUsed = subscription.members.length;
        const availableSeats = subscription.paid_seats + subscription.certified_seats - seatsUsed;  
        if (availableSeats > 0) {
            subscription.members.push(member);
        } else {
            throw new Error('Subscription is full');
        }

        await this.updateSubscription({...subscription, id: subscriptionId});
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> {
        try {
            const docRef = doc(this.collection, subscriptionId);
            const subscription = await getDoc(docRef);
            
            if (!subscription.exists()) {
                throw new Error('Subscription not found');
            }

            const currentData = subscription.data();
            const updatedMembers = currentData.members.filter(
                (member: SubscriptionMember) => member.user_id !== memberId
            );

            // Use a more specific update that only touches the members array
            await updateDoc(docRef, {
                members: updatedMembers
            }).catch(error => {
                // Log the error but don't throw if the update was successful
                console.warn('Warning: Update notification failed but data was updated:', error);
            });
        } catch (error) {   
            console.error('Error removing member:', error);
            // Only throw if it's not a permission error on the notification
            if (error.message !== 'Missing or insufficient permissions.') {
                throw error;
            }
        }
    }

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        if (!subscription.code) {
            const code = await generateSubscriptionCode();
            subscription.code = normalizeCode(code);
        } else if  (subscription.code === SubscriptionConstants.NOCODE) {
            // do nothing
        } else {
            subscription.code = normalizeCode(subscription.code);
            const isUnique = await verifySubscriptionCodeUnique(subscription.code);
            if (!isUnique) {
                throw new Error('Subscription code is not unique');
            }
        }
        
        if (!subscription.env)
        {
           subscription.env = import.meta.env.VITE_VERCEL_ENV || 'development'; // set the runtime environment 
        }
        
        console.log('createSubscription', JSON.stringify(subscription, null, 2));

        const newSubscriptionRef = await addDoc(this.collection, subscription);
        return { ...subscription, id: newSubscriptionRef.id };
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        const { id, ...updateData } = subscription;
        console.log('updateSubscription', id, updateData);
        await updateDoc(
            doc(this.collection, subscription.id),
            updateData
        );
        return subscription;
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        await updateDoc(doc(this.collection, subscriptionId), { deleted: true });
    }

    async getAllSubscriptions(): Promise<Subscription[]> {
        try {
            const snapshot = await getDocs(this.collection);
            return snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Subscription));
        } catch (error) {
            console.error('Error fetching all subscriptions:', error);
            throw error;
        }
    }
}


export class SubscriptionService {
    private repository: ISubscriptionRepository;

    constructor(repository: ISubscriptionRepository) {
        this.repository = repository;
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        return this.repository.getSubscription(subscriptionId);
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {
        return this.repository.addMember(subscriptionId, member);
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> { 
        return this.repository.removeMember(subscriptionId, memberId);
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        return this.repository.getAdminSubscriptions(userId);
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        return this.repository.getSubscriptionsByIds(subscription_ids);
    }   

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        return this.repository.createSubscription(subscription);
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        return this.repository.updateSubscription(subscription);
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        return this.repository.deleteSubscription(subscriptionId);
    }

    async getAllSubscriptions(): Promise<Subscription[]> {
        return this.repository.getAllSubscriptions();
    }
}