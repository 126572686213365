import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, type Firestore } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

// Development fallback configuration
const devConfig = {
  apiKey: "AIzaSyBn7FLGrr9vENU7uHetOa02am__CvHL5K0",
  authDomain: "findinggood-dfcaf.firebaseapp.com",
  projectId: "findinggood-dfcaf",
  storageBucket: "findinggood-dfcaf.firebasestorage.app",
  messagingSenderId: "335726476719",
  appId: "1:335726476719:web:f6894f8ec7518daa2ac35a",
  measurementId: "G-E50HB9N2TK"
};

// Use environment variables if available, otherwise fall back to dev config
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || devConfig.apiKey,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || devConfig.authDomain,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || devConfig.projectId,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || devConfig.storageBucket,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || devConfig.messagingSenderId,
  appId: import.meta.env.VITE_FIREBASE_APP_ID || devConfig.appId,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || devConfig.measurementId
};

// Initialize Firebase with validation
let app;
let auth;
let db: Firestore;

try {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
} catch (error) {
  console.error('Error initializing Firebase:', error);
  toast.error('Failed to initialize Firebase. Please check configuration.');
  throw error;
}

export { auth, db };