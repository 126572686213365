import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Header from './components/shared/Header';
import { AuthProvider } from './contexts/AuthContext';
import { ReflectionsProvider } from './contexts/ReflectionsContext';
import { RequireAuth } from './features/auth/components/RequireAuth';
import Footer from './components/shared/Footer';
import TermsOfService from './components/legal/TermsOfService';
import { RequirePaidAccess } from './features/auth/components/RequirePaidAccess';
import { RequireAdmin } from './features/auth/components/RequireAdmin';
import TestHome from './components/TestHome';
import Landing from './components/Landing';
import ConnectorType from './components/ConnectorType/ConnectorType';
import PublicShare from './components/shared/PublicShare';
import DeeperReflections from './components/DeeperReflections';
import DailyReflection from './components/DailyReflection';
import Purchase from './components/Purchase';
import Buy from './components/Buy';
import ExperienceView from './components/experiences/core/ExperienceView';
import SharedExperiences from './components/SharedExperiences/SharedExperiences';
import SuccessMap from './components/SuccessMap';
import AIAnalyzer from './components/AIAnalyzer';
import AdminLayout from './components/Admin/AdminLayout';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminLogin from './components/Admin/AdminLogin';
import AdminApps from './components/Admin/AdminApps';
import DeepDives from './components/Admin/DeepDives';
import Curations from './components/Admin/Curations';
import AdminExperiences from './components/Admin/AdminExperiences';
import CompletedThemes from './components/Admin/CompletedThemes';
import UserActivity from './components/Admin/UserActivity';
import UsersTable from './components/Admin/UsersTable';
import Groups from './components/Admin/Groups';
import Dashboard from './components/Dashboard/Dashboard';
import UserLogin from './components/UserAuth/UserLogin';
import NotFound from './components/NotFound';
import GroupAdminDashboard from './components/Admin/GroupAdminDashboard';
import SubscriptionAdmin from './components/Admin/SubscriptionManager/SubscriptionAdmin';
import SubscriptionManagment from './components/Admin/SubscriptionManager/SubscriptionManagement';
import WelcomeSubscriptions from './components/WelcomeSubscriptions';
import TestingPage from './components/Admin/Testing/TestingPage';
import GroupManagementDashboard from './components/GroupManagement/GroupManagementDashboard';
import { inject } from '@vercel/analytics';
import Profile from './components/Profile/Profile';
import Vistage from './components/Vistage/Vistage';
import VistageSignup from './components/Vistage/VistageSignup';
import CurationView from './components/CurationView';
import PacksPage from './components/Packs/PacksPage';
import Subscriptions from './components/Admin/Subscriptions/Subscriptions';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; 
}

// Add a FooterWrapper component to conditionally render the footer
function FooterWrapper() {
  const location = useLocation();
  // Don't render footer on the packs page
  if (location.pathname === '/packs') return null;
  return <Footer />;
}

export default function App() {
  inject();
  return (
    <AuthProvider>
      <Router>
        <ReflectionsProvider>
        <Toaster position="top-center" />
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Header />
            <ScrollToTop />
            <div className="flex-1">
              <Routes>
                <Route path="/packs" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <PacksPage />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/" element={<Landing />} />
                <Route path="/assessments/connector-type" element={
                  <RequireAuth>
                    <ConnectorType />
                  </RequireAuth>
                } />
                <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
                <Route path="/vistagesignup" element={<VistageSignup />} />
                <Route path="/vistage" element={
                  <RequireAuth>
                    <Vistage />
                  </RequireAuth>
                } />
                <Route path="/welcome" element={
                  <WelcomeSubscriptions />
                } />
                <Route path="/test-home" element={
                  <RequireAuth>
                    <TestHome />
                  </RequireAuth>
                } />
                <Route path="/deeper-reflections" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <DeeperReflections />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/purchase" element={<Buy />} />
                <Route path="/buy" element={<Buy />} />
                <Route path="/login" element={<UserLogin />} />
                <Route path="/share/:shareId" element={<PublicShare />} />

                {/* Dynamic Routes */}
                <Route path="/exp/:experienceRoute" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <ExperienceView />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/curation/:curationRoute" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <CurationView />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />

                {/* Protected Routes */}
                <Route path="/:experienceRoute" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <ExperienceView />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/year-in-review" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <ExperienceView experienceRoute="year-in-review" />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/daily-reflection" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <ExperienceView experienceRoute="daily" />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/daily-initial" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <ExperienceView experienceRoute="daily-initial" />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/shared-experiences" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <SharedExperiences />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/success-map" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <SuccessMap />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />
                <Route path="/analyzer" element={
                  <RequireAuth>
                    <RequirePaidAccess>
                      <AIAnalyzer />
                    </RequirePaidAccess>
                  </RequireAuth>
                } />

                {/* Admin Routes */}
                <Route path="/admin" element={<RequireAuth><RequireAdmin><AdminLayout><AdminDashboard /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/users" element={<RequireAuth><RequireAdmin><AdminLayout><UsersTable /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/groups" element={<RequireAuth><RequireAdmin><AdminLayout><Groups /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/deep-dives" element={<RequireAuth><RequireAdmin><AdminLayout><DeepDives /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/curations" element={<RequireAuth><RequireAdmin><AdminLayout><Curations /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/experiences" element={<RequireAuth><RequireAdmin><AdminLayout><AdminExperiences /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/completed-themes" element={<RequireAuth><RequireAdmin><AdminLayout><CompletedThemes /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/activity" element={<RequireAuth><RequireAdmin><AdminLayout><UserActivity /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/apps" element={<RequireAuth><RequireAdmin><AdminLayout><AdminApps /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/group-admin" element={<RequireAuth><AdminLayout><GroupAdminDashboard /></AdminLayout></RequireAuth>} />
                <Route path="/admin/subscription-admin" element={<RequireAuth><AdminLayout><SubscriptionAdmin /></AdminLayout></RequireAuth>} />
                <Route path="/admin/testing" element={<RequireAuth><RequireAdmin><AdminLayout><TestingPage /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/subscriptions" element={<RequireAuth><RequireAdmin><AdminLayout><Subscriptions /></AdminLayout></RequireAdmin></RequireAuth>} />
                <Route path="/admin/subscription-manage" element={<RequireAuth><SubscriptionManagment /></RequireAuth>} />
                
                {/* Dashboard Route */}
                <Route
                  path="/dashboard"
                  element={
                    <div className="min-h-screen bg-gray-50">
                      <RequireAuth>
                        <RequirePaidAccess>
                          <Dashboard />
                        </RequirePaidAccess>
                      </RequireAuth>
                    </div>
                  }
                />

                {/* Admin Routes */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="/admin"
                  element={
                    <RequireAuth>
                      <RequireAdmin>
                      <AdminLayout>
                        <AdminDashboard />
                      </AdminLayout>
                      </RequireAdmin>
                    </RequireAuth>
                  }
                >
                  <Route index element={<AdminDashboard />} />
                  <Route path="users" element={<UsersTable />} />
                  <Route path="groups" element={<Groups />} />
                  <Route path="group-admin" element={<GroupAdminDashboard />} />
                  <Route path="deep-dives" element={<DeepDives />} />
                  <Route path="experiences" element={<AdminExperiences />} />
                  <Route path="completed-themes" element={<CompletedThemes />} />
                  <Route path="activity" element={<UserActivity />} />
                  <Route path="apps" element={<AdminApps />} />
                </Route>

                {/* Legal Routes */}
                <Route path="/terms" element={<TermsOfService />} />

                {/* 404 Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <FooterWrapper />
          </div>
        </ReflectionsProvider>
      </Router>
    </AuthProvider>
  );
}