import React, { useState, useEffect } from "react";
import CurrentSubscriptionCard from "./CurrentSubscriptionCard"
import ModifySubscription from "./ModifySubscription"
import SubscriptionHistory from "./SubscriptionHistory"
import SubscriptionSelector from "./SubscriptionSelector"
import { useAuth } from "../../../contexts/AuthContext";
import { UserService, FirebaseUserRepository } from "../../../lib/dal/users";
import { FirebaseSubscriptionRepository, SubscriptionService, Subscription, CalculateSubscription } from '../../../lib/dal/subscriptions';
import { AccessLevel } from "../../../enums/AccessLevel";
import toast from "react-hot-toast";


export default function SubscriptionManagement() {
  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
  const userService = new UserService(new FirebaseUserRepository());
  const [subscriptions, setSubscriptions] = useState<CalculateSubscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<CalculateSubscription>();
  const { user, userData, dispatch } = useAuth();

  const refreshSubscriptions = async () => {
    try {
      const loadedSubscriptions = userData?.access_level === AccessLevel.Admins
        ? await subscriptionService.getAllSubscriptions() as CalculateSubscription[]
        : await subscriptionService.getAdminSubscriptions(user.uid) as CalculateSubscription[];

      loadedSubscriptions.map(subscription => {
        const totalSeats = subscription.paid_seats + subscription.certified_seats;
        const usedSeats = subscription.members?.length || 0;
        subscription.totalSeats = totalSeats;
        subscription.usedSeats = usedSeats;
      });    

      setSubscriptions(loadedSubscriptions);

      // Update selected subscription if it exists
      if (selectedSubscription) {
        const updatedSelected = loadedSubscriptions.find(s => s.id === selectedSubscription.id);
        setSelectedSubscription(updatedSelected);
      }
    } catch (error) {
      console.error('Error refreshing subscriptions:', error);
      toast.error('Failed to refresh subscriptions');
    }
  };

  useEffect(() => {
    const loadSubscriptions = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const subscriptionId = params.get('id');
        
        if (!user) return;
        
        let loadedSubscriptions: CalculateSubscription[] = [];
        if (userData?.access_level === AccessLevel.Admin) {
          loadedSubscriptions = await subscriptionService.getAllSubscriptions() as CalculateSubscription[];
        } else {
          loadedSubscriptions = await subscriptionService.getAdminSubscriptions(user.uid) as CalculateSubscription[];
        }

        // for each subscription calculate total seats and used seats
        loadedSubscriptions.map(subscription => {
          const totalSeats = subscription.paid_seats + subscription.certified_seats;
          const usedSeats = subscription.members?.length || 0;
          subscription.totalSeats = totalSeats;
          subscription.usedSeats = usedSeats;
        });    
        setSubscriptions(loadedSubscriptions);

        if (subscriptionId && loadedSubscriptions.length > 0) {
          const subscription: CalculateSubscription | undefined = loadedSubscriptions.find(s => s.id === subscriptionId) as CalculateSubscription;
          setSelectedSubscription(subscription);
        }


      } catch (error) {
        console.error('Error loading subscriptions:', error);
        toast.error('Failed to load subscriptions');
      }
    };

    loadSubscriptions();
  }, [user, userData]);

  return (
    <div className="container mx-auto p-8 max-w-6xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl text-gray-700">Finding Blueberries Subscription</h1>
        Select Subscription: 
        <SubscriptionSelector 
          subscriptions={subscriptions} 
          selectedSubscription={selectedSubscription} 
          onSubscriptionSelect={setSelectedSubscription} 
        />
      </div>

      <div className="grid md:grid-cols-2 gap-6 mb-8">
        {selectedSubscription && (
        <CurrentSubscriptionCard 
          subscription={selectedSubscription} 
        />
        )}  
        {selectedSubscription && (
          <ModifySubscription 
            subscription={selectedSubscription}
            onSubscriptionUpdate={refreshSubscriptions}
          />
        )}
      </div>

      <div className="text-sm text-gray-500 mb-8">
        If you are having issues with your subscription, please describe your needs to{" "}
        <a href="info@findinggood.com" className="text-green-500">
          info@findinggood.com
        </a>
        , and our specialist will contact you within 24 hours to discuss your individual conditions
      </div>

      <SubscriptionHistory history={selectedSubscription?.history} />
    </div>
  )
}