import { Settings2 } from "lucide-react"
import { Button } from "../../ui/Button"
import { Badge } from "@/components/ui/badge"
import { CalculateSubscription } from "@/lib/dal/subscriptions"
import { Clock } from "lucide-react";

interface CurrentSubscriptionCardProps {
    subscription: CalculateSubscription;
}

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return 'N/A';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    return date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    });
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid Date';
  }
};

const calculateTotal = (price?: number, seats?: number): string => {
  if (!price || !seats) return '$0';
  return `$${(price * seats).toFixed(2)}`;
};

export default function CurrentSubscriptionCard({ subscription }: CurrentSubscriptionCardProps) {
  // Remove router usage

  if (!subscription) {
    return <div className="bg-gradient-to-r from-indigo-400/10 to-purple-500/10 rounded-lg p-6">No subscription data available</div>;
  }

  const {
    price_plan,
    usedSeats = 0,
    totalSeats = 0,
    renewal_status = 'unknown',
    end_date,
    paid_seats = 0,
    subscription_source = 'N/A'
  } = subscription;

  // Add safe defaults for price_plan values
  const safePrice = price_plan?.price ?? 0;
  const safeDuration = price_plan?.duration ?? 'N/A';
  const safePlanName = price_plan?.planName ?? 'No Plan Name';
  const safeDescription = price_plan?.description ?? 'No description available';
  const safeTotal = calculateTotal(price_plan?.price, paid_seats);

  return (
    <div className="bg-gradient-to-r from-indigo-400 to-purple-500 text-white rounded-lg p-6">
      <div className="mb-4">
        <h2 className="text-xl font-semibold">{safePlanName}</h2>
        <p className="text-white/80 text-sm">{safeDescription}</p>
      </div>

      <div className="grid grid-cols-2 gap-4 py-4 border-t border-b border-white/20">
        <div>
          <p className="text-2xl font-semibold">{usedSeats} / {totalSeats}</p>
          <p className="text-white/80 text-sm">Seats</p>
        </div>
        <div className="text-center">
          <Clock className="w-6 h-6 mx-auto mb-2" />
          <p className="text-white/80 text-sm">{renewal_status?.toUpperCase() || 'Unknown'}</p>
        </div>
      </div>

      <div className="space-y-3 mt-4">
        <div className="flex justify-between">
          <span className="text-white/80">
            Total per {safeDuration === 'monthly' ? 'month' : 'year'}:
          </span>
          <span className="font-semibold">{safeTotal}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-white/80">Per seat:</span>
          <span className="font-semibold">${safePrice}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-white/80">Payment type:</span>
          <span className="font-semibold">{safeDuration.toUpperCase()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-white/80">Next payment:</span>
          <span className="font-semibold">{formatDate(end_date)}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-white/80">Payment Source:</span>
          <span className="font-semibold">{subscription_source.toLocaleUpperCase()}</span>
        </div>
      </div>

    { totalSeats > 0 && (
      <Button 
        variant="outline" 
        className="w-full mt-6 bg-white/10 hover:bg-white/20 text-white border-white/30 hover:border-white/60"
        onClick={() => window.location.href = `/admin/subscription-admin?id=${subscription.id}`}
      >
        <Settings2 className="mr-2 h-4 w-4" />
        Manage subscription seats
        <Badge variant="secondary" className="ml-2 bg-white/20 text-white">
          {paid_seats > 0 ? paid_seats : 0}
        </Badge>
      </Button>
    )}
    </div>
  );
}
