import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  addDoc, 
  doc, 
  getDoc,
  limit 
} from 'firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import { db } from '../../../lib/firebase';
import type { Experience, Theme, CompletedTheme } from '../../../lib/firebase/types';
import { generateStructuredInfo } from '../../../lib/openai';
import { Firestore } from 'firebase/firestore';
import { saveCompletedTheme } from '../../../lib/firebase/themes';

// Import shared components
import ThemeSummaryViewer from '../../shared/ThemeSummaryViewer';
import QuestionModal from '../../YourStory/QuestionModal';
import ThemeOverview from '../../YourStory/ThemeOverview';
import CompletionModal from '../../YourStory/CompletionModal';
import ExperienceIntro from '../../shared/ExperienceIntro';
import LockedThemeModal from '../../shared/LockedThemeModal';
import ZenLoader from '../../shared/ZenLoader';
import ThemeCard from '../../shared/ThemeCard';
import ExperienceComplete from '../../shared/ExperienceComplete';

interface ThemeContent {
  responses: { [key: number]: string };
  summary?: string;
  completedAt?: string;
  isCompleted: boolean;
}

interface ExperienceViewProps {
  experienceRoute?: string;
}

// Add new modal type enum
type ActiveModal = 'none' | 'question' | 'overview' | 'summary' | 'loading';

export default function ExperienceView({ experienceRoute: propExperienceRoute }: ExperienceViewProps) {
  const navigate = useNavigate();
  const { experienceRoute: routeParam } = useParams();
  const route = propExperienceRoute || routeParam;
  const { user, userData } = useAuth();

  // Replace individual modal states with a single activeModal state
  const [activeModal, setActiveModal] = useState<ActiveModal>('none');
  const [experience, setExperience] = useState<Experience | null>(null);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null);
  const [currentDay, setCurrentDay] = useState(1);
  const [showThemeGrid, setShowThemeGrid] = useState(true);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [themeContent, setThemeContent] = useState<{ [key: string]: ThemeContent }>({});
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [createdFor, setCreatedFor] = useState<string>('');
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [experienceSummaryId, setExperienceSummaryId] = useState<string | null>(null);

  // Load completed themes
  useEffect(() => {
    const loadCompletedThemes = async () => {
      if (!user || !experience?.id) return;

      try {
        console.log('Loading completed themes for user:', user.uid);
        
        // First, check for experience summary
        const summaryQuery = query(
          collection(db, 'completedThemes'),
          where('userId', '==', user.uid),
          where('experienceId', '==', experience.id),
          where('themeType', '==', 'experience_summary'),
          orderBy('completedAt', 'desc'),
          limit(1)
        );
        
        const summarySnapshot = await getDocs(summaryQuery);
        if (!summarySnapshot.empty) {
          const summaryDoc = summarySnapshot.docs[0];
          const summaryData = { id: summaryDoc.id, ...summaryDoc.data() } as CompletedTheme;
          setExperienceSummary(summaryData);
          setExperienceSummaryId(summaryDoc.id);
        }

        // Then load regular theme completions
        const themesQuery = query(
          collection(db, 'completedThemes'),
          where('userId', '==', user.uid), 
          where('experienceId', '==', experience.id),
          where('themeType', '==', 'theme'),
          orderBy('completedAt', 'desc')
        );
        
        const themesSnapshot = await getDocs(themesQuery);
        const completedThemes = themesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as CompletedTheme[];

        // Update theme content with completed themes
        const updatedContent = completedThemes.reduce((acc, theme) => {
          if (theme.themeId) {
            acc[theme.themeId] = {
              responses: theme.responses.reduce((r: { [key: number]: string }, response) => {
                r[response.questionId] = response.content;
                return r;
              }, {}),
              summary: theme.summary,
              completedAt: theme.completedAt,
              isCompleted: true
            };
          }
          return acc;
        }, {} as { [key: string]: ThemeContent });

        setThemeContent(updatedContent);
        setHasAllThemesCompleted(checkAllThemesCompleted());
      } catch (error) {
        console.error('Error loading completed themes:', error);
        setError(error instanceof Error ? error.message : 'Failed to load completed themes');
      }
    };

    loadCompletedThemes();
  }, [user, experience?.id]);

  // Load experience and themes
  useEffect(() => {
    const loadExperience = async () => {
      if (!route) return;

      try {
        console.log('🔵 Loading experience for route:', route);
        
        const experiencesRef = collection(db, 'experiences');
        const experienceQuery = query(
          experiencesRef,
          where('route', '==', route),
          limit(1)
        );
        
        const experienceSnapshot = await getDocs(experienceQuery);
        if (experienceSnapshot.empty) {
          throw new Error('Experience not found');
        }
        
        const yearInReview = {
          id: experienceSnapshot.docs[0].id,
          ...experienceSnapshot.docs[0].data()
        } as Experience;
        
        setExperience(yearInReview);
        
        // Get themes
        const themesRef = collection(db, 'themes');
        const themesQuery = query(
          themesRef,
          where('experienceId', '==', yearInReview.id),
          orderBy('order', 'asc')
        );
        
        const themesSnapshot = await getDocs(themesQuery);
        const loadedThemes = themesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Theme[];
        
        setThemes(loadedThemes);
        
      } catch (error) {
        console.error('Error loading experience:', error);
        setError('Failed to load experience');
      }
    };

    loadExperience();
  }, [route]);

  // Update checkAllThemesCompleted to be more robust
  const checkAllThemesCompleted = () => {
    if (!themes.length) return false;
    return themes.every(theme => {
      const content = themeContent[theme.id];
      return content?.isCompleted && content?.completedAt;
    });
  };

  // Remove the duplicate experience summary check useEffect since it's now handled in loadCompletedThemes
  useEffect(() => {
    const allCompleted = checkAllThemesCompleted();
    setHasAllThemesCompleted(allCompleted);
  }, [themeContent, themes]);

  const handleGenerateExperienceSummary = async () => {
    if (!user || !experience || !userData) return;
    
    try {
      setIsGeneratingExperienceSummary(true);
      
      // Get all completed themes for this experience
      const allThemes = Object.values(themeContent).filter(t => t.isCompleted);
      const allResponses = allThemes.flatMap(theme => 
        Object.entries(theme.responses).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          content
        }))
      );

      // Use custom prompt if available, otherwise use default
      const systemPrompt = experience.experienceSummaryPrompt || `Create a comprehensive experience summary that synthesizes insights from all completed themes. Structure the response in markdown format with these sections:

# Experience Journey
A narrative overview connecting insights across all themes

## Key Moments & Milestones
3-5 significant achievements or turning points

## Personal Growth & Learning
3-5 areas where you've demonstrated growth or gained insights

## Impact & Relationships
3-5 ways your actions and decisions influenced others or strengthened relationships

## Looking Ahead
A forward-looking synthesis that builds on your experiences`;

      const response = await generateStructuredInfo(
        JSON.stringify(allResponses),
        { model: experience.experienceSummaryModel || 'gpt-4', temperature: 0.7 },
        systemPrompt
      );

      if (!response?.data) {
        throw new Error('Failed to generate summary');
      }

      const summaryData: Omit<CompletedTheme, 'id'> = {
        userId: user.uid,
        userName: `${userData.firstName} ${userData.lastName}`,
        themeId: 'experience_summary',
        themeTitle: experience.title,
        themeType: 'experience_summary',
        experienceId: experience.id,
        experienceName: experience.title,
        responses: allResponses.map(r => ({
          questionId: r.questionId,
          content: r.content,
          createdAt: new Date().toISOString()
        })),
        summary: response.data,
        completedAt: new Date().toISOString(),
        questions: themes.flatMap(theme => theme.questions)
      };

      // Save to Firestore
      const docRef = await saveCompletedTheme(summaryData, null);
      
      // Wait a moment to ensure Firestore write is complete
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Fetch the newly created document to ensure we have the latest data
      const summaryDoc = await getDoc(doc(db as Firestore, 'completedThemes', docRef.id));
      if (!summaryDoc.exists()) {
        throw new Error('Failed to load newly created summary');
      }

      const newSummary = { id: summaryDoc.id, ...summaryDoc.data() } as CompletedTheme;
      setExperienceSummary(newSummary);
      setSelectedThemeId(summaryDoc.id);
      setActiveModal('summary');
      toast.success('Experience summary generated!');
    } catch (error) {
      console.error('Error generating experience summary:', error);
      toast.error('Failed to generate experience summary');
    } finally {
      setIsGeneratingExperienceSummary(false);
    }
  };

  const handleGenerateSummary = async (responses: { [key: number]: string }) => {
    if (!currentTheme || !user) return;

    try {
      setIsGeneratingSummary(true);
      setError(null);

      const systemPrompt = currentTheme.systemPrompt || 
        'Create a reflective summary that synthesizes the key insights from the responses. Structure the response in markdown format with these sections:\n\n# Key Insights\nMain takeaways and patterns\n\n## Growth Areas\nAreas of personal or professional development\n\n## Action Items\nSpecific, actionable next steps';

      const formattedResponses = Object.entries(responses).map(([questionId, answer]) => {
        const question = currentTheme.questions.find(q => q.id === parseInt(questionId));
        return `Question: ${question?.text}\nAnswer: ${answer}`;
      }).join('\n\n');

      const summary = await generateStructuredInfo(
        formattedResponses,
        { model: experience?.themeSummaryModel || 'gpt-3.5-turbo', temperature: 0.7 },
        systemPrompt
      );

      if (!summary?.data) {
        throw new Error('Failed to generate summary');
      }

      return summary.data;
    } catch (error) {
      console.error('Error generating summary:', error);
      throw new Error('Failed to generate summary');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleSaveResponse = async () => {
    if (!currentTheme || !user) return;

    try {
      setError(null);
      const themeId = currentTheme.id;
      const currentContent = themeContent[themeId]?.responses || {};
      const updatedTheme: ThemeContent = {
        responses: {
          ...currentContent,
          [currentDay]: currentContent[currentDay] || ''
        },
        isCompleted: false
      };

      setThemeContent(prev => ({
        ...prev,
        [themeId]: updatedTheme
      }));
      
      if (currentDay === currentTheme.questions.length) {
        const responses = updatedTheme.responses;
        const summary = await handleGenerateSummary(responses);

        const completedTheme: Omit<CompletedTheme, 'id'> = {
          userId: user.uid,
          userName: userData ? `${userData.firstName} ${userData.lastName}` : 'Anonymous',
          themeId: currentTheme.id,
          experienceId: experience?.id || '',
          experienceName: experience?.title || '',
          responses: Object.entries(responses).map(([questionId, content]) => ({
            questionId: parseInt(questionId),
            question: currentTheme.questions[parseInt(questionId) - 1].text,
            content,
            createdAt: new Date().toISOString()
          })),
          summary,
          completedAt: new Date().toISOString(),
          questions: currentTheme.questions,
          themeType: 'theme'
        };

        if (createdFor?.trim()) {
          (completedTheme as any).createdFor = createdFor.trim();
        }

        const docRef = await saveCompletedTheme(completedTheme, currentTheme);
        
        setThemeContent(prev => ({
          ...prev,
          [themeId]: {
            ...updatedTheme,
            summary,
            completedAt: new Date().toISOString(),
            isCompleted: true
          }
        }));

        setSelectedThemeId(docRef.id);
        setActiveModal('summary');
      }
    } catch (error) {
      console.error('Error saving response:', error);
      setError('Failed to save response');
    }
  };

  const handleStartTheme = (theme: Theme) => {
    setCurrentTheme(theme);
    setActiveModal('overview');
  };

  const handleStartQuestions = () => {
    setCurrentDay(1);
    setActiveModal('question');
    setShowThemeGrid(false);
  };

  const handleViewSummary = async (theme: Theme) => {
    try {
      const latestTheme = await getLatestCompletedTheme(theme.id);
      if (latestTheme) {
        setSelectedThemeId(latestTheme.id);
        setActiveModal('summary');
        setShowThemeGrid(false);
      } else {
        toast.error('No completed summary found for this theme');
      }
    } catch (error) {
      console.error('Error viewing summary:', error);
      toast.error('Failed to load theme summary');
    }
  };

  const getLatestCompletedTheme = async (themeId: string) => {
    if (!user) return null;

    try {
      console.log('Fetching completed theme for:', { themeId, userId: user.uid });
      
      const completedThemesRef = collection(db, 'completedThemes');
      const q = query(
        completedThemesRef,
        where('themeId', '==', themeId),
        where('userId', '==', user.uid),
        orderBy('completedAt', 'desc'),
        limit(1)
      );
      
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        return {
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data()
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching completed theme:', error);
      return null;
    }
  };

  const closeAllModals = () => {
    setActiveModal('none');
    setShowThemeGrid(true);
    setCurrentTheme(null);
    setCurrentDay(1);
    setSelectedThemeId(null);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!experience || !themes.length) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-600 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {experience && (
          <>
            <div className="mb-8">
              <h1 className="text-3xl font-bold text-gray-900">{experience.title}</h1>
              <p className="mt-2 text-lg text-gray-600">{experience.description}</p>
              {hasAllThemesCompleted && (
                <ExperienceComplete
                  onGenerateSummary={handleGenerateExperienceSummary}
                  onViewSummary={() => {
                    if (experienceSummary?.id) {
                      setSelectedThemeId(experienceSummary.id);
                      setActiveModal('summary');
                    } else {
                      console.error('Experience summary not found');
                      toast.error('Unable to load summary');
                    }
                  }}
                  isGenerating={isGeneratingExperienceSummary}
                  hasSummary={!!experienceSummary}
                  totalThemes={themes.length}
                />
              )}
            </div>

            {showThemeGrid && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {themes.map((theme) => (
                  <ThemeCard
                    key={theme.id}
                    theme={theme}
                    isCompleted={themeContent[theme.id]?.isCompleted}
                    onStart={() => handleStartTheme(theme)}
                    onViewSummary={() => handleViewSummary(theme)}
                  />
                ))}
              </div>
            )}

            {currentTheme && activeModal === 'question' && (
              <QuestionModal
                theme={currentTheme}
                currentQuestion={currentDay}
                answers={themeContent[currentTheme.id]?.responses || {}}
                onClose={closeAllModals}
                onAnswer={(questionId, answer) => {
                  if (!currentTheme) return;
                  setThemeContent(prev => ({
                    ...prev,
                    [currentTheme.id]: {
                      ...prev[currentTheme.id],
                      responses: {
                        ...(prev[currentTheme.id]?.responses || {}),
                        [questionId]: answer
                      }
                    }
                  }));
                }}
                onNext={() => setCurrentDay(prev => prev + 1)}
                onPrevious={() => setCurrentDay(prev => prev - 1)}
                onComplete={handleSaveResponse}
                isGeneratingSummary={isGeneratingSummary}
              />
            )}

            {currentTheme && activeModal === 'overview' && (
              <ThemeOverview
                isOpen={true}
                onClose={closeAllModals}
                theme={currentTheme}
                experience={experience}
                showCreatedFor={currentTheme.showCreatedFor}
                onCreatedForChange={setCreatedFor}
                createdFor={createdFor}
                onStart={handleStartQuestions}
              />
            )}

            {selectedThemeId && user && activeModal === 'summary' && (
              <ThemeSummaryViewer
                isOpen={true}
                onClose={closeAllModals}
                completedThemeId={selectedThemeId}
                userId={user.uid}
              />
            )}

            {isGeneratingSummary && activeModal === 'loading' && <ZenLoader />}
          </>
        )}
      </div>
    </div>
  );
} 