import React, { useState } from 'react';
import { X } from 'lucide-react';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion, setDoc, getDoc } from 'firebase/firestore';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '@/lib/firebase';
import { toast } from 'react-hot-toast';
import type { Subscription } from '@/lib/dal/subscriptions';
import { UserService, FirebaseUserRepository } from '@/lib/dal/users';
import { SubscriptionService, FirebaseSubscriptionRepository } from '@/lib/dal/subscriptions';
import { AccessLevel } from '@/enums/AccessLevel';

interface Member {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  team: string;
}

interface AddSubscriptionMemberModalProps {
  subscription: Subscription;
  onClose: (newUser?: Member) => void;
}

export default function AddSubscriptionMemberModal({
  subscription,
  onClose
}: AddSubscriptionMemberModalProps) {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [team, setTeam] = useState('default');
  const [newTeamName, setNewTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const userService = new UserService(new FirebaseUserRepository());
  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());

  // Get unique teams from subscription members
  const uniqueTeams = Array.from(new Set(subscription.members.map(member => member.team || 'default')));

  const checkValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (checkValidEmail(newEmail)) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', newEmail.toLowerCase()));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        setValidEmail(false);
        toast.error("This email is already in the system. Please login to upgrade.");
      } else {
        setValidEmail(true);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      const newUserData = { 
        email, 
        firstName, 
        lastName, 
        access_level: AccessLevel.PersonalMembership,
        signup_source: 'Subscription Add Member', 
      };

      const newUser = await userService.createUserWithoutLogin(newUserData, password, subscription.id);
      const finalTeam = team === 'new-team' ? newTeamName : team;
      await subscriptionService.addMember(subscription.id, { user_id: newUser.id, team: finalTeam });
      sendPasswordResetEmail(auth, email);
      toast.success('New user account created');
      onClose(newUser);
    } catch (error) {
      console.error('Error adding member:', error);
      toast.error('Failed to add member');
    }
  };

  const handleCancel = () => {
    onClose();  // No data passed when cancelled
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Add Subscription Member</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="mb-4">
          <h4 className="text-sm font-medium text-gray-700 mb-3">Create New User</h4>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email address"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Temporary Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={6}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <p className="mt-1 text-sm text-gray-500">
                User will need to reset this password on first login
              </p>
            </div>

            {/*  No Teams yet
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Team
              </label>
              <select
                value={team}
                onChange={(e) => setTeam(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                {uniqueTeams.map((teamName) => (
                  <option key={teamName} value={teamName}>
                    {teamName === 'default' ? 'Default Team' : teamName}
                  </option>
                ))}
                <option value="new-team">+ New Team</option>
              </select>
              {team === 'new-team' && (
                <input
                  type="text"
                  placeholder="Enter new team name"
                  value={newTeamName}
                  onChange={(e) => setNewTeamName(e.target.value)}
                  required
                  className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              )}
            </div>
            {*/}
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleCancel}
                className="px-4 py-2 text-gray-700 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading || !validEmail}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                {loading ? 'Adding...' : 'Create & Add User'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
