import React, { useEffect, useState } from 'react';
import type { Theme, Experience } from '../../lib/firebase/types';

interface ThemeOverviewProps {
  theme: Theme;
  experience: Experience;
  isOpen: boolean;
  onClose: () => void;
  showCreatedFor?: boolean;
  onCreatedForChange: (value: string) => void;
  createdFor: string;
  onStart: () => void;
}

export default function ThemeOverview({
  theme,
  experience,
  isOpen,
  onClose,
  showCreatedFor,
  onCreatedForChange,
  createdFor,
  onStart,
}: ThemeOverviewProps) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const truncatedDescription = theme.description.length > 150 
    ? theme.description.substring(0, 150) + '...' 
    : theme.description;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
        onClose();
        if (e.key === 'Enter' && isOpen) {
          onStart();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onStart, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] overflow-hidden">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/30" onClick={onClose} />
      
      {/* Modal Container - absolute positioning for true center */}
      <div className="absolute inset-0 flex items-center justify-center p-4">
        {/* Modal Content */}
        <div className="relative bg-white rounded-xl w-full max-w-md p-6 max-h-[calc(100vh-2rem)] overflow-y-auto">
          <h2 className="text-xl font-semibold text-purple-900">
            {theme.title}
          </h2>

          <div className="mt-4 space-y-4">
            {experience.introMessage && (
              <div>
                <h3 className="font-medium text-gray-900">Introduction</h3>
                <p className="mt-1 text-gray-600">{experience.introMessage}</p>
              </div>
            )}

            <div>
              <h3 className="font-medium text-gray-900">About this theme</h3>
              <p className="mt-1 text-gray-600">
                {showFullDescription ? theme.description : truncatedDescription}
                {theme.description.length > 150 && (
                  <button
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    className="ml-1 text-purple-600 hover:text-purple-700"
                  >
                    {showFullDescription ? 'Read less' : 'Read more'}
                  </button>
                )}
              </p>
            </div>
          </div>

          {showCreatedFor && (
            <div className="mt-6">
              <label htmlFor="createdFor" className="block text-sm font-medium text-gray-700 mb-2">
                Who is this reflection for?
              </label>
              <input
                type="text"
                id="createdFor"
                value={createdFor}
                onChange={(e) => onCreatedForChange(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Optional: Enter a name"
              />
            </div>
          )}

          <div className="mt-6 flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              onClick={onStart}
              className="px-4 py-2 text-sm bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Start Theme
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}