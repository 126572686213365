import React from 'react';
import { motion } from 'framer-motion';
import { FileText, RefreshCw } from 'lucide-react';
import ThemeSummaryViewer from './ThemeSummaryViewer';

interface ExperienceCompleteProps {
  onGenerateSummary: () => void;
  onViewSummary: () => void;
  isGenerating: boolean;
  hasSummary: boolean;
  totalThemes: number;
}

export default function ExperienceComplete({
  onGenerateSummary,
  onViewSummary,
  isGenerating,
  hasSummary,
  totalThemes
}: ExperienceCompleteProps) {
  return (
    <>
      <div className="bg-gradient-to-r from-indigo-50 to-purple-50 border border-indigo-100 rounded-lg p-6 mb-8">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <div>
            <h3 className="text-xl font-semibold text-indigo-900 mb-2">
              Experience Complete! 🎉
            </h3>
            {totalThemes > 1 ? (
              <p className="text-indigo-700">
                {hasSummary 
                  ? 'Your experience summary is ready to view!' 
                  : 'Generate a summary to see insights across your entire journey.'}
              </p>
            ) : (
              <p className="text-indigo-700">
                Congratulations on completing this experience!
              </p>
            )}
          </div>
          
          <div className="flex flex-col sm:flex-row gap-4">
            {hasSummary && (
              <button
                onClick={onViewSummary}
                className="flex items-center justify-center space-x-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                disabled={!hasSummary}
              >
                <FileText className="w-5 h-5" />
                <span>View Experience Summary</span>
              </button>
            )}
            {totalThemes > 1 && (
              <button
                onClick={onGenerateSummary}
                disabled={isGenerating}
                className={`flex items-center justify-center space-x-2 px-6 py-3 rounded-lg transition-colors ${
                  hasSummary 
                    ? 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                    : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
              >
                {isGenerating ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span>Generating...</span>
                  </>
                ) : (
                  <>
                    <RefreshCw className="w-5 h-5" />
                    <span>{hasSummary ? 'Regenerate Summary' : 'Generate Experience Summary'}</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
} 