
import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserService, FirebaseUserRepository, MockUserRepository, FbbUser } from '../lib/dal/users';
import { FirebaseSubscriptionRepository, SubscriptionService, MockSubscriptionRepository, SubscriptionMember  } from '../lib/dal/subscriptions';
import { useNavigate } from 'react-router-dom';
import { AccessLevel } from '../enums/AccessLevel';
import { toast } from 'react-hot-toast';
import { useAuth } from "../contexts/AuthContext";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Duration } from '../enums/PurchaseEnums';
import { 
    PricePlan, 
    RenewalStatus, 
    Subscription, 
    SubscriptionSource,
    Transaction,
    TransactionType } from '../lib/dal/subscriptions';

interface PricingData {
    id: string;
    name: string;
    amount: number;
    currency: string;
    interval: string;
    intervalCount: number;
};

interface StripeData {
    email: string;
    firstName: string;
    lastName: string;
    stripeCustomerId: string;
    subscriptionId: string;
    phone: string;
    paymentTotal: number;
    pricePlan: PricingData;
  }


export default function WelcomeSubscriptions() {
    const [searchParams] = useSearchParams();
    const { user, userData, dispatch } = useAuth();
    const [stripeData, setStripeData] = useState<StripeData | null>(null);
    const [userExists, setUserExists] = useState(false);
    const [existingUserId, setExistingUserId] = useState('');
    const [passwordisSet, setPasswordIsSet] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const userService = new UserService(new FirebaseUserRepository());
    const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const navigate = useNavigate();
    const setupComplete = useRef(false);
    const setupInprogress = useRef(false);

    // Fetch stripe data once searchParams are available
    useEffect(() => {
    let isSubscribed = true;

    const fetchStripeData = async () => {
        const sessionId = searchParams.get('s');
        if (!sessionId) return;

        try {
            const response = await fetch('/api/handle-stripe-subscribe', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionId }),
            });
            if (!isSubscribed) return; // Don't update state if unmounted
            if (!response.ok) throw new Error('Failed to fetch Stripe data');
            
            const data = await response.json();
            let firstName = 'unknown';
            let lastName = 'unknown';
            if (data.customerDetails) {
                [firstName, lastName] = data.customerDetails.name.split(' ');
            }

            if (!isSubscribed) return; // Don't update state if unmounted

            const stripeData: StripeData = {
                email: data.customerDetails.email || '',
                firstName: firstName,
                lastName: lastName,
                stripeCustomerId: data.stripeCustomerId,
                subscriptionId: data.subscriptionId,
                phone: data.customerDetails.phone || '',
                paymentTotal: data.amount_total * 0.01,
                pricePlan: {
                    id: data.price_plan.id,
                    name: data.price_plan.name || 'Finding Blueberries Subscription',
                    amount: data.price_plan.amount * 0.01,
                    currency: data.price_plan.currency,
                    interval: data.price_plan.interval,
                    intervalCount: data.price_plan.interval_count,
                },

            };

            setStripeData(stripeData);
            //console.log("STRIPE DATA FETCHED: " + JSON.stringify(stripeData));
        } catch (error) {
        console.error('Error fetching Subscription data:', error);
        toast.error('Failed to fetch subscription');
        }
    };

    //console.log("FETCHING STRIPE DATA");
    fetchStripeData();
    return () => {
        isSubscribed = false;
    };
    }, [searchParams]);

    // Check if user exists
    useEffect(() => {
    const checkUserExists = async () => {
        if (setupInprogress.current) return;
        if (user) {
            setUserExists(true);
            setExistingUserId(user.uid);
            return;
        }

        if (stripeData && stripeData.email) {
            const userQuery = query(collection(db, 'users'), 
                where('email', '==', stripeData.email.toLocaleLowerCase()));
            const querySnapshot = await getDocs(userQuery);
            setUserExists(!querySnapshot.empty);
            if (!querySnapshot.empty) {
              setExistingUserId(querySnapshot.docs[0].id);
            }
        }
    };

    checkUserExists();
    }, [user, stripeData]);

    // perform setup  if userExists or password is set
    useEffect(() => {
        if (!stripeData) return;
        let runningSetup = true;

        const performSetup = async () => {
            if (!runningSetup && setupComplete.current) return;
            if (!userExists && !passwordisSet) return;
            if (setupComplete.current) {
              //console.log('Setup already completed, skipping');
              return;
            }
            setupInprogress.current = true;

            // Add guard clause
            if (!stripeData || (!userExists && !passwordisSet)) {
              //console.log('Missing required data, skipping setup');
              return;
            }

            //console.log("PERFORMING SETUP");
            let redirectPath = "/";
            let createUser = false;
            let userId = existingUserId;
            if (userExists) {
                //console.log("USER EXISTS - create subscription");
            }
            if (!userExists && passwordisSet) {
                createUser = true;
                //console.log("PASSWORD IS SET - create user then subscription");
            }

            // Setup user and subscription data
            let quantity = 1;
            if (searchParams.has('q')) {
                quantity = parseInt(searchParams.get('q') || '1');
            }

            const startDateObj = new Date();
            const endDateObj = new Date(startDateObj);
            endDateObj.setMonth(startDateObj.getMonth() + 1);
            if (searchParams.get('t') === Duration.ANNUAL) {
              endDateObj.setFullYear(startDateObj.getFullYear() + 1);
            }
            const startDate = startDateObj.toISOString();
            const endDate = endDateObj.toISOString();

            const pricePlan: PricePlan = {
                planID: stripeData.pricePlan.id,
                planName: stripeData.pricePlan.name,
                price: stripeData.pricePlan.amount,
                duration: searchParams.get('t') as Duration,
                description: `${quantity} seats at $${stripeData.pricePlan.amount} per seat. ${searchParams.get('t')} subscription`,
            }

            const transaction: Transaction = {
                date: startDate,
                type: TransactionType.CHARGE,
                amount: stripeData.paymentTotal,
                description: `Subscription charge for ${quantity} seats at $${stripeData.pricePlan.amount} ${searchParams.get('t')}`,
            }

            let defaultMembers: SubscriptionMember[] =[];
            if (quantity == 1) {  // this should add firstTime bulk purchase users to the subscription.
                defaultMembers = [{user_id: userId}];
            }
            // create subscription
            const newSubscriptionData: Subscription = {
                admin_id: userId,
                start_date: startDate,
                end_date: endDate,
                paid_seats: quantity,
                certified_seats: 0,
                external_subscription_id: stripeData?.subscriptionId || '',
                subscription_source: SubscriptionSource.STRIPE,
                external_customer_id: stripeData?.stripeCustomerId || '',
                renewal_status: RenewalStatus.ACTIVE,
                members: defaultMembers,
                price_plan: pricePlan,
                history: [transaction]
            }

            let newSubscription = null;
            try {
                newSubscription = await subscriptionService.createSubscription(newSubscriptionData);
                //console.log("SUBSCRIPTION CREATED: " + newSubscription.id);
            } catch (error) {
                console.error('Error creating subscription:', error);
                toast.error('Failed to create subscription');
                return;
            }

            if (quantity > 1) {  
              redirectPath = "/admin/subscription-admin"
            }
            

            if (!createUser) {
              const updatedUser = {...userData, id: userId, subscription_ids: [newSubscription.id],
              }
              userService.updateUser(updatedUser);
              //console.log("USER UPDATED WITH SUBSCRIPTION ID", JSON.stringify(updatedUser));
              
            }
    

            if (createUser) {
                //console.log("CREATING USER");
            
                const accessLevel = AccessLevel.PersonalMembership;
                const newUserData = {
                    email: stripeData?.email || '',
                    firstName: stripeData?.firstName || '',
                    lastName: stripeData?.lastName || '',
                    access_level: accessLevel,
                    stripe_customer_id: stripeData?.stripeCustomerId || '',
                    subscription_ids: [newSubscription.id],
                    phone: stripeData?.phone || '', 
                    signup_source: 'Stripe Subscription',
                }
                try {
                    //console.log("CREATING USER with data:" + JSON.stringify(newUserData));
                    const newUser = await userService.createUser(newUserData, password);
                    userId = newUser.id;
                    const updatedSub = {...newSubscription, admin_id: userId, members: [{user_id: userId}]};
                    //console.log("💡 USER CREATED: " + JSON.stringify(newUser, null, 2));
                    //console.log("😜 UPDATING SUBSCRIPTION: " + JSON.stringify(updatedSub, null, 2));

                    subscriptionService.updateSubscription(updatedSub);

                    dispatch({ type: "UPDATE_ACCESS_LEVEL", payload: accessLevel });
                } catch (error) {
                    console.error('Error creating user:', error);
                    toast.error('Failed to create user');
                    return;
                }
            }

            //console.log("SETUP COMPLETE");
            await sleep(3000);
            setupComplete.current = true;
            setupInprogress.current = false;
            navigate(redirectPath, { replace: true });
        };

        performSetup();
        return () => {
            runningSetup = false;
        };
    }, [userExists, passwordisSet, stripeData]);
    

    //TODO:  FOR NOW THIS IS JUST NEW PURCHASES.

    // If user doesnot exists - create new user
    // collect password.
    // if quantity is 1, create new user with an access level of personal_membership
    // else create new user with an access level of free.

    //if user exists.. just create the subscription
    
   
    // create new subscription
    // add transaction to subscription
    // add user to subscription adminId


    return (
        <div>
          <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-4xl font-bold text-indigo-900 mb-4">Welcome to Finding Blueberries.</h1>
            {userExists ? (
              <p>Account already Exists - Setting up Subscription</p>
            ) : (
              <>
                {passwordisSet ? 'Preparing your account' : 'Almost there...'}
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setPasswordIsSet(true);
                    }}
                    className="space-y-4"
                  >
                    <label className="block text-sm font-medium text-gray-700">
                      {!passwordisSet ? 'Create a Password for your new account' : 'Creating Account'}
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      minLength={6}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <div className="flex justify-end space-x-3 mt-6">
                      <button
                        type="submit"
                        disabled={password.length < 6 || passwordisSet}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
                      >
                        {passwordisSet ? 'Creating Account' : 'Set Password'}
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      );
}