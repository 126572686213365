import { Slider } from "@/components/ui/slider"
import { Button } from "../../ui/Button"
import { Toggle } from "@/components/ui/toggle"
import { Info } from "lucide-react"
import { useAuth } from "@/contexts/AuthContext";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { useEffect, useState } from "react"
import { Duration, PurchaseType } from "@/enums/PurchaseEnums"
import { PRICING, 
    calculateAnnualPrice,
     calculateMonthlyPrice, 
     getPriceForDuration, 
    CalculateSubscription, 
    ProrationCalculation, 
    calculateProration,
    SubscriptionSource,
    SubscriptionService,
    FirebaseSubscriptionRepository,
    TransactionType,
    Transaction
} from "@/lib/dal/subscriptions"
import toast from "react-hot-toast"

interface ModifySubscriptionProps {
    subscription: CalculateSubscription;
    onSubscriptionUpdate?: () => void;
}

const dateToUnixTimestamp = (date: Date): number => {
  return Math.floor(date.getTime() / 1000);
};

// Convert Unix timestamp to Date
const unixTimestampToDate = (timestamp: number): Date => {
  return new Date(timestamp * 1000);
};

export default function ModifySubscription({ subscription, onSubscriptionUpdate }: ModifySubscriptionProps) {
    // Add this check at the start
    if (subscription?.subscription_source === SubscriptionSource.SPONSORED) {
        return (
            <div className="relative">
                <div className="absolute inset-0 bg-gray-200/50 backdrop-blur-sm z-10 flex items-center justify-center rounded-lg">
                    <p className="text-gray-600 font-medium text-lg">Unable to modify Sponsored Subscription</p>
                </div>
                <div className="opacity-50 pointer-events-none">
                    <ModifySubscriptionContent subscription={subscription} />
                </div>
            </div>
        );
    }

    return <ModifySubscriptionContent subscription={subscription} onSubscriptionUpdate={onSubscriptionUpdate} />;
}

// Move the existing component content into a new component
function ModifySubscriptionContent({ subscription, onSubscriptionUpdate }: ModifySubscriptionProps) {
    const [newSeats, setNewSeats] = useState(subscription?.paid_seats || 1);
    const [duration, setDuration] = useState<Duration>(
        subscription?.price_plan?.duration || Duration.ANNUAL
    );
    const [prorationDetails, setProrationDetails] = useState<ProrationCalculation | null>(null);
    const [changeHappend, setChangeHappend] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);  
    const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
    const { user } = useAuth();

    useEffect(() => {
        if (subscription?.paid_seats) {
            setNewSeats(subscription.paid_seats);
        }
        if (subscription?.price_plan?.duration) {
            setDuration(subscription.price_plan.duration);
        }
    }, [subscription]);

    useEffect(() => {
        if (subscription?.paid_seats && subscription.end_date) {
            const endDate = new Date(subscription.end_date);
            // Don't calculate proration if subscription is expired
            if (endDate < new Date()) {
                setProrationDetails(null);
                return;
            }
            const purchaseType = newSeats >= 5 ? PurchaseType.GROUP : PurchaseType.INDIVIDUAL;
            const proration = calculateProration(
                subscription.paid_seats,
                newSeats,
                endDate,
                duration,
                purchaseType
            );
            setProrationDetails(proration);
        }
    }, [newSeats, duration, subscription?.end_date, subscription?.paid_seats]);

    const handleSeatChange = (newSeats: number) => {
        setNewSeats(newSeats);
        if (newSeats !== subscription.paid_seats) {
            setChangeHappend(true);
        } else {
            setChangeHappend(false);
        }
    }

    const calculateNewCharges = () => {
        if (!changeHappend) return 0;
        
        const currentPurchaseType = newSeats >= 5 ? PurchaseType.GROUP : PurchaseType.INDIVIDUAL;
        const basePrice = getPriceForDuration(currentPurchaseType, duration);
        return basePrice * (newSeats - subscription.paid_seats);
    }


    const calculateTotalDue = () => {
        if (!changeHappend) return 0;
        const totalNewCharge = calculateNewCharges();
        
        // If there's proration, add it to the total
        const prorationCharge = prorationDetails ? 
            prorationDetails.existingSeatsDifferenceCharge : 0;
            
        return totalNewCharge + prorationCharge;
    };

    const isChangingToMonthlyAllowed = () => {
        if (!subscription?.end_date) return true;
        
        const currentEndDate = new Date(subscription.end_date);
        const now = new Date();
        
        // If switching from annual to monthly would result in an earlier end date
        if (subscription.price_plan?.duration === Duration.ANNUAL) {
            const remainingMonths = Math.ceil((currentEndDate.getTime() - now.getTime()) / (30 * 24 * 60 * 60 * 1000));
            // Don't allow switch to monthly if more than 1 month remains
            return remainingMonths <= 1;
        }
        
        return true;
    };

    const cancelSubscription = async () => { 
        console.log('Cancel subscription client side');
        const response = await fetch("/api/cancel-stripe-subscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ subscriptionId: subscription.external_subscription_id})  
        })

        if (!response.ok) {
            throw new Error("Failed to cancel Stripe subscription");
        }

        const data = await response.json();
        console.log("CANCEL CALL" , JSON.stringify(data, null, 2));
    };

    const updateStripeSubscription = async (newEndDate: string) => {
        setIsUpdating(true);
        try {
            const newDate = new Date(newEndDate);
            const unixTimestamp = dateToUnixTimestamp(newDate);
            const amount = calculateTotalDue().toFixed(2);

            console.log('Update subscription with:', {
                newSeats,
                duration,
                totalCharge: amount,
                newEndDate: unixTimestamp
            });

            if (subscription && subscription.subscription_source === SubscriptionSource.STRIPE) {
                console.log("SUBSCRIPTION ID   " + subscription.external_subscription_id);
                const response = await fetch("/api/fetch-stripe-subscription", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ subscriptionId: subscription.external_subscription_id})  
                })

                if (!response.ok) {
                    throw new Error("Failed to fetch Stripe subscription data");
                }

                const data = await response.json();
                console.log(JSON.stringify(data, null, 2));


                const neededParams = {
                    subscription_id: subscription.external_subscription_id,
                    item_id: data.subscriptionItemId,
                    item_price: data.price_plan.id,
                    billing_cycle_anchor: unixTimestamp,
                    quantity: newSeats,

                }
                const updateResponse = await fetch("/api/modify-stripe-subscription", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(neededParams)
                });

                if (!updateResponse.ok) {
                    throw new Error("Failed to update Stripe subscription");
                }

                const updateData = await updateResponse.json();
                setChangeHappend(false);
                toast.success('Subscription updated successfully');

                subscription.end_date = new Date(newEndDate).toISOString();
                subscription.paid_seats = updateData.quantity;

                const transaction: Transaction = {
                    date: new Date().toISOString(),
                    type: TransactionType.SUBSCRIPTION_CHANGE,
                    amount: calculateTotalDue(),
                    description: `Subscription updated to ${newSeats} seats and new end date ${subscription.end_date}`,
                    user_id: user.uid || '',
                };

                subscription.history.push(transaction);

                await subscriptionService.updateSubscription(subscription);
                if (onSubscriptionUpdate) {
                    onSubscriptionUpdate();
                }
                
            }
        } catch (error) {
            console.error('Failed to update subscription:', error);
            toast.error('Failed to update subscription');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleDurationChange = (newDuration: Duration) => {
        if (newDuration === Duration.MONTHLY && !isChangingToMonthlyAllowed()) {
            return;
        }
        setDuration(newDuration);
        setChangeHappend(true);
    };

    if (!subscription) {
        return <div className="bg-gradient-to-r from-indigo-400/10 to-purple-500/10 rounded-lg p-6">No subscription data available</div>;
    }

    const isExpired = new Date(subscription.end_date) < new Date();
    const canRenew = isExpired || subscription.renewal_status !== 'active';

    const costPerSeat = function(purchaseTypeOverride?: PurchaseType, durationOverride?: Duration) {
        let purchaseType = purchaseTypeOverride ?  purchaseTypeOverride : newSeats >= 5 ? PurchaseType.GROUP : PurchaseType.INDIVIDUAL;
        let dur = durationOverride ? durationOverride : duration;
        let costPerSeat = getPriceForDuration(purchaseType, dur); // monthly or annual
        return costPerSeat;
    }

    let maxCost = costPerSeat(PurchaseType.INDIVIDUAL, Duration.MONTHLY);
    if (duration === Duration.ANNUAL) {
        maxCost = maxCost * 12;
    }
    const discount = Math.round( (1 - (costPerSeat()/maxCost)) * 100, 2);
    const savings = Math.round( (maxCost - costPerSeat()) * newSeats, 2);
 


    return (
        <div className="bg-white rounded-lg border p-6 flex flex-col min-h-[450px]">
            <div className="mb-6">
                <h2 className="text-xl font-medium">Modify Subscription</h2>
                <p className="text-gray-500 text-sm">save {PRICING.ANNUAL_DISCOUNT * 100}% with yearly payment</p>
            </div>

            <div className="flex justify-end mb-6">
                <div className="flex items-center space-x-2 border rounded-lg">
                    <Toggle 
                        pressed={duration === Duration.ANNUAL} 
                        onPressedChange={() => handleDurationChange(Duration.ANNUAL)}
                        className="data-[state=on]:bg-green-50 px-4"
                    >
                        YEARLY <span className="text-green-500 ml-1">-{PRICING.ANNUAL_DISCOUNT * 100}%</span>
                    </Toggle>
                    <Toggle 
                        pressed={duration === Duration.MONTHLY}
                        onPressedChange={() => handleDurationChange(Duration.MONTHLY)}
                        className={`px-4 ${!isChangingToMonthlyAllowed() ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!isChangingToMonthlyAllowed()}
                    >
                        MONTHLY
                    </Toggle>
                </div>
            </div>

            <div className="mb-8">
                <div className="flex justify-between mb-2">
                    <span className="text-2xl font-medium">{newSeats}</span>
                    <span className="text-gray-500 text-sm">save an additional %20 with bulk purchase (5+) seats</span>
                </div>
                <Slider 
                    min={1}
                    max={100} 
                    step={1} 
                    className="mb-4 [&_[role=slider]]:bg-indigo-500 [&_[role=slider]]:border-indigo-500 [&_[role=slider]]:hover:bg-indigo-600 [&_[role=track]]:bg-indigo-500/50" 
                    value={[newSeats]}
                    onValueChange={(values) => handleSeatChange(values[0])}
                />
            </div>

            <div className={`transition-opacity duration-200 ${!changeHappend ? 'opacity-50' : ''}`}>
                <h3 className="text-base font-medium mb-2">New Charges</h3>
                <div className="grid grid-cols-6 gap-4 mb-4">
                    <div>
                        <p className="text-xl font-medium">{newSeats - subscription.paid_seats}</p>
                        <p className="text-gray-500 text-sm">new seats</p>
                    </div>
                    <div>
                        <p className="text-xl font-medium">
                            ${costPerSeat()} 
                            <span className="text-gray-400 line-through text-sm">${(duration === Duration.ANNUAL ? 12 : 1) * costPerSeat(PurchaseType.INDIVIDUAL, Duration.MONTHLY )}</span>
                        </p>
                        <p className="text-gray-500 text-sm">per seat</p>
                    </div>
                    <div className="col-span-2">
                        <p className="text-xl font-medium">${calculateNewCharges()}</p>
                        <p className="text-gray-500 text-sm">New Charges</p>
                    </div>
                    <div className="col-span-2">
                        <p className="text-xl font-medium">%{discount}</p>
                        { discount > 0 && (
                        <p className="text-gray-500 text-sm">Save with {duration === Duration.ANNUAL ? 'yearly' : ''} {newSeats >= 5 ? 'bulk': ''} plan</p>
                        )}
                    </div>
                </div>
            </div>

            <div>
                {changeHappend && prorationDetails && (
                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                        <h3 className="text-base font-medium mb-2">Proration Details</h3>
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-span-2">
                                <p className="text-xl font-medium">{subscription.paid_seats}</p>
                                <p className="text-gray-500 text-sm">current seats</p>
                            </div>
                            <div className="col-span-2">
                                <p className="text-xl font-medium">
                                    {prorationDetails.newEndDate.toLocaleDateString()}
                                </p>
                                <p className="text-gray-500 text-sm">new end date</p>
                            </div>
                            <div className="col-span-2">
                                <p className="text-xl font-medium">
                                    <span>
                                        ${prorationDetails.existingSeatsDifferenceCharge.toFixed(2)}
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Info className="inline-block w-4 h-4 ml-1 text-gray-400 cursor-help" />
                                                </TooltipTrigger>
                                                <TooltipContent side="right" align="center">
                                                    <div className="max-w-xs">{prorationDetails.description}</div>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </span>
                                </p>
                                <p className="text-gray-500 text-sm">proration adjustment</p>
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="grid grid-cols-6 gap-4 items-center">
                    <div className="col-span-3">
                        <p className="text-3xl font-medium">
                            ${calculateTotalDue().toFixed(2)}
                        </p>
                        <p className="text-gray-500">Total due today</p>
                    </div>
                    <div className="col-span-3 flex justify-end gap-2">
                        <Button 
                            className="bg-green-500 hover:bg-green-600"
                            disabled={!changeHappend || isUpdating}
                            onClick={() => {
                                updateStripeSubscription(prorationDetails?.newEndDate);
                            }}
                        >
                            {isUpdating ? 'UPDATING...' : 'CHANGE'}
                        </Button>
                        {false && canRenew ? (
                            <Button
                                variant="outline"
                                className="border-blue-500 text-blue-500 hover:bg-blue-50"
                                disabled={isUpdating}
                                onClick={() => {
                                    console.log('Renew subscription');
                                    //TODO: do this
                                }}
                            >
                                RENEW
                            </Button>
                        ) : (
                            <Button
                                variant="outline"
                                className="border-red-500 text-red-500 hover:bg-red-50"
                                disabled={isUpdating}
                                onClick={() => {cancelSubscription()}}
                            >
                                CANCEL
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
