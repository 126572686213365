import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Pack, PackMessage } from '../../lib/firebase/types';
import { doc, getDoc, deleteDoc, collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { User } from '../../types';
import MentionInput from './MentionInput';

interface PackMessagesProps {
  pack: Pack;
  messages: PackMessage[];
  onSendMessage: (content: string) => void;
  packId: string;
  setMessages: (messages: PackMessage[]) => void;
}

const MAX_LINK_LENGTH = 50;

// Helper function to format dates
const formatDate = (date: Date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long', 
      month: 'long', 
      day: 'numeric' 
    });
  }
};

// Helper function to format time
const formatTime = (date: Date) => {
  return date.toLocaleTimeString('en-US', { 
    hour: 'numeric', 
    minute: '2-digit',
    hour12: true 
  });
};

export default function PackMessages({ pack, messages, onSendMessage, packId, setMessages }: PackMessagesProps) {
  const { user } = useAuth();
  const [memberDetails, setMemberDetails] = useState<{[key: string]: any}>({});
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastMessageRef, setLastMessageRef] = useState<any>(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

  // Scroll to bottom when messages load initially or new messages arrive
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Function to load more messages
  const loadMoreMessages = useCallback(async () => {
    if (isLoadingMore || !hasMore) return;

    try {
      setIsLoadingMore(true);
      const q = query(
        collection(db, 'packMessages'),
        where('packId', '==', packId),
        orderBy('createdAt', 'desc'),
        startAfter(lastMessageRef),
        limit(25)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        setHasMore(false);
        return;
      }

      // Create a Map of existing messages for quick lookup
      const existingMessages = new Map(messages.map(msg => [msg.id, msg]));
      
      // Process new messages, ensuring uniqueness
      snapshot.docs.forEach(doc => {
        const message = { id: doc.id, ...doc.data() } as PackMessage;
        if (!existingMessages.has(message.id)) {
          existingMessages.set(message.id, message);
        }
      });

      // Convert to array and sort by creation time
      const allMessages = Array.from(existingMessages.values())
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      setLastMessageRef(snapshot.docs[snapshot.docs.length - 1]);
      setShouldScrollToBottom(false); // Don't scroll to bottom when loading older messages
      setMessages(allMessages);
    } catch (error) {
      console.error('Error loading more messages:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [packId, messages, lastMessageRef, isLoadingMore, hasMore]);

  // Handle scroll
  const handleScroll = useCallback(() => {
    if (!messagesContainerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    // Load more when user scrolls near the top (older messages)
    if (scrollTop < 100 && !isLoadingMore && hasMore) {
      loadMoreMessages();
    }

    // Update auto-scroll behavior based on scroll position
    const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
    setShouldScrollToBottom(isNearBottom);
  }, [loadMoreMessages, isLoadingMore, hasMore]);

  // Add scroll event listener
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Set initial last message reference when messages change
  useEffect(() => {
    if (messages.length > 0 && !lastMessageRef) {
      const firstMessage = messages[0];
      setLastMessageRef(firstMessage);
    }
  }, [messages]);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (!pack.members) return;
      
      const details: {[key: string]: any} = {};
      const memberIds = Array.isArray(pack.members) ? pack.members : Object.keys(pack.members);
      
      for (const memberId of memberIds) {
        try {
          const userDocRef = doc(db, 'users', memberId);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            details[memberId] = userDoc.data();
          }
        } catch (error) {
          console.error('Error fetching member details:', error);
        }
      }
      setMemberDetails(details);
    };

    fetchMemberDetails();
  }, [pack.members]);

  const formatMessageContent = (content: string) => {
    // URL regex pattern
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    // Mention regex pattern - matches @userId
    const mentionPattern = /@([a-zA-Z0-9]+)/g;
    
    let parts = [];
    let lastIndex = 0;

    // First find all URLs and mentions
    const urlMatches = [...content.matchAll(urlPattern)];
    const mentionMatches = [...content.matchAll(mentionPattern)];
    const allMatches = [...urlMatches, ...mentionMatches].sort((a, b) => (a.index || 0) - (b.index || 0));

    for (const match of allMatches) {
      const matchText = match[0];
      const matchIndex = match.index || 0;

      // Add text before the match
      if (matchIndex > lastIndex) {
        parts.push(<span key={`text-${lastIndex}`}>{content.slice(lastIndex, matchIndex)}</span>);
      }

      if (matchText.startsWith('http')) {
        // Handle URL
        const displayUrl = matchText.length > MAX_LINK_LENGTH 
          ? matchText.substring(0, MAX_LINK_LENGTH) + '...' 
          : matchText;
        parts.push(
          <a 
            key={`url-${matchIndex}`}
            href={matchText}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {displayUrl}
          </a>
        );
      } else {
        // Handle mention
        const userId = matchText.substring(1); // Remove @ symbol
        const userData = memberDetails[userId];
        const displayName = userData 
          ? `@${userData.firstName} ${userData.lastName}`.trim()
          : matchText;
        
        parts.push(
          <span 
            key={`mention-${matchIndex}`}
            className="bg-indigo-100 text-indigo-800 rounded px-1"
          >
            {displayName}
          </span>
        );
      }

      lastIndex = matchIndex + matchText.length;
    }

    // Add remaining text
    if (lastIndex < content.length) {
      parts.push(<span key={`text-${lastIndex}`}>{content.slice(lastIndex)}</span>);
    }

    return parts;
  };

  const handleDeleteMessage = async (messageId: string) => {
    try {
      await deleteDoc(doc(db, 'packMessages', messageId));
      setMessages(messages.filter(msg => msg.id !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  // Group messages by date and sort in ascending order
  const messagesByDate = messages.reduce((groups, message) => {
    const date = new Date(message.createdAt).toDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {} as { [key: string]: PackMessage[] });

  // Sort dates in ascending order
  const sortedDates = Object.keys(messagesByDate).sort((a, b) => 
    new Date(a).getTime() - new Date(b).getTime()
  );

  return (
    <div className="h-full flex flex-col overflow-hidden">
      {/* Messages container */}
      <div ref={messagesContainerRef} className="flex-1 overflow-y-auto min-h-0">
        <div className="flex flex-col justify-end min-h-full">
          {isLoadingMore && (
            <div className="text-center py-2">
              <div className="inline-block animate-spin rounded-full h-4 w-4 border-2 border-indigo-600 border-t-transparent"></div>
            </div>
          )}
          <div className="space-y-8 p-4">
            {sortedDates.map(date => (
              <div key={date}>
                <div className="flex justify-center mb-4">
                  <div className="text-gray-500 text-sm">
                    {formatDate(new Date(date))}
                  </div>
                </div>
                <div className="space-y-3">
                  {messagesByDate[date].map((message) => (
                    <div 
                      key={message.id} 
                      className={`flex ${message.userId === user?.uid ? 'justify-end' : 'justify-start'}`}
                    >
                      <div 
                        className={`max-w-[70%] ${message.userId === user?.uid ? 'bg-indigo-100' : 'bg-gray-100'} 
                          rounded-lg p-3 relative group`}
                      >
                        <div className="font-medium text-sm text-gray-600 flex justify-between items-center gap-2">
                          <span>{message.userName}</span>
                          {message.userId === user?.uid && (
                            <button
                              onClick={() => handleDeleteMessage(message.id)}
                              className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-400 hover:text-red-500"
                              title="Delete message"
                            >
                              🗑️
                            </button>
                          )}
                        </div>
                        <div>{formatMessageContent(message.content)}</div>
                        <div className="text-xs text-gray-400 mt-1">
                          {formatTime(new Date(message.createdAt))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Input container */}
      <div className="flex-shrink-0 border-t bg-white p-4">
        <MentionInput 
          onSend={onSendMessage}
          packMembers={Object.entries(memberDetails).map(([uid, data]) => ({
            uid,
            firstName: data.firstName,
            lastName: data.lastName
          }))}
          placeholder="Type a message..."
        />
      </div>
    </div>
  );
}
