import React, { useState, useEffect } from 'react';
import { X, Plus } from 'lucide-react';
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import type { Experience } from '../../lib/firebase/types';
import EditThemeModal from './EditThemeModal';
import ThemesList from './ThemesList';

interface EditExperienceModalProps {
  isOpen: boolean;
  onClose: () => void;
  experience: Experience | null;
  onSaved: () => void;
}

export default function EditExperienceModal({
  isOpen,
  onClose,
  experience,
  onSaved
}: EditExperienceModalProps) {
  const [form, setForm] = useState({
    title: '',
    description: '',
    route: '',
    order: experience?.order || 1,
    isActive: true,
    imageUrl: '',
    introMessage: '',
    themeSummaryModel: 'gpt-3.5-turbo',
    experienceSummaryModel: 'gpt-4',
    experienceSummaryPrompt: ''
  });
  const [loading, setLoading] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [refreshThemes, setRefreshThemes] = useState(0);

  useEffect(() => {
    if (experience) {
      setForm({
        title: experience.title,
        description: experience.description,
        route: experience.route,
        order: experience.order || 1,
        isActive: experience.isActive,
        imageUrl: experience.imageUrl || '',
        introMessage: experience.introMessage || '',
        themeSummaryModel: experience.themeSummaryModel || 'gpt-3.5-turbo',
        experienceSummaryModel: experience.experienceSummaryModel || 'gpt-4',
        experienceSummaryPrompt: experience.experienceSummaryPrompt || ''
      });
    } else {
      setForm({
        title: '',
        description: '',
        route: '',
        order: 1,
        isActive: true,
        imageUrl: '',
        introMessage: '',
        themeSummaryModel: 'gpt-3.5-turbo',
        experienceSummaryModel: 'gpt-4',
        experienceSummaryPrompt: ''
      });
    }
  }, [experience]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const timestamp = new Date().toISOString();
      const data = {
        ...form,
        updatedAt: timestamp
      };

      if (experience) {
        await updateDoc(doc(db, 'experiences', experience.id), data);
        toast.success('Experience updated successfully');
      } else {
        data.createdAt = timestamp;
        await addDoc(collection(db, 'experiences'), data);
        toast.success('Experience created successfully');
      }

      onSaved();
      onClose();
    } catch (error) {
      console.error('Error saving experience:', error);
      toast.error('Failed to save experience');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-5xl w-full p-6 my-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">
            {experience ? 'Edit Experience' : 'Add Experience'}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              required
              rows={3}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Route</label>
            <input
              placeholder="e.g. shared-experiences"
              type="text"
              value={form.route}
              onChange={(e) => setForm({ ...form, route: e.target.value })}
              required
              pattern="[a-z0-9-]+"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <p className="mt-1 text-sm text-gray-500">
              Use lowercase letters, numbers and hyphens only. This will be used in the URL.
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Order</label>
            <input
              type="number"
              value={form.order}
              onChange={(e) => setForm({ ...form, order: parseInt(e.target.value) || 1 })}
              required
              min={1}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Welcome Message</label>
            <textarea
              value={form.introMessage}
              onChange={(e) => setForm({ ...form, introMessage: e.target.value })}
              rows={2}
              placeholder="Optional welcome message shown when users start the experience"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Experience Summary Prompt</label>
            <textarea
              value={form.experienceSummaryPrompt}
              onChange={(e) => setForm({ ...form, experienceSummaryPrompt: e.target.value })}
              rows={6}
              placeholder="Leave blank to use default experience summary prompt"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 font-mono text-sm"
            />
            <p className="mt-1 text-sm text-gray-500">
              Custom prompt to use when generating the final experience summary. If left blank, the default prompt will be used.
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Image URL</label>
            <input
              type="url"
              value={form.imageUrl}
              onChange={(e) => setForm({ ...form, imageUrl: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              checked={form.isActive}
              onChange={(e) => setForm({ ...form, isActive: e.target.checked })}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Active
            </label>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Theme Summary Model</label>
              <select
                value={form.themeSummaryModel}
                onChange={(e) => setForm({ ...form, themeSummaryModel: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                <option value="gpt-4">GPT-4</option>
              </select>
              <p className="mt-1 text-sm text-gray-500">Model used for individual theme summaries</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Experience Summary Model</label>
              <select
                value={form.experienceSummaryModel}
                onChange={(e) => setForm({ ...form, experienceSummaryModel: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                <option value="gpt-4">GPT-4</option>
              </select>
              <p className="mt-1 text-sm text-gray-500">Model used for final experience summary</p>
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Experience'}
            </button>
          </div>
        </form>
        {experience && (
          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Themes</h3>
              <button
                onClick={() => setShowThemeModal(true)}
                className="flex items-center space-x-2 px-3 py-1.5 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200"
              >
                <Plus className="w-4 h-4" />
                <span>Add Theme</span>
              </button>
            </div>
            <ThemesList 
              experienceId={experience.id} 
              key={refreshThemes} // Force re-render when themes change
            />
          </div>
        )}

        <EditThemeModal
          isOpen={showThemeModal}
          onClose={() => setShowThemeModal(false)}
          experienceId={experience?.id}
          onSaved={() => {
            setShowThemeModal(false);
            setRefreshThemes(prev => prev + 1); // Trigger theme list refresh
          }}
        />
      </div>
    </div>
  );
}