import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, Treemap, ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';

const COLORS = ['#4B3F72', '#6A0572', '#AB83A1', '#D4A5A5', '#D4C1EC', '#A3A1F7', '#6A0572', '#4B3F72'];

interface Subscription {
  start_date: string;
  code: string;
  price_plan?: { price: number };
  paid_seats?: number;
  certified_seats?: number;
}

interface SubscriptionDashboardProps {
  subscriptions: Subscription[];
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${payload[0].payload.start_date}`}</p>
        <p className="intro">{`Count: ${payload[0].value}`}</p>
        <p className="intro">{`Subscription Code: ${payload[0].payload.codes.join(', ')}`}</p>
      </div>
    );
  }
  return null;
};

const PriceTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Date: ${payload[0].payload.start_date}`}</p>
        <p className="intro">{`Total Price: $${payload[0].value}`}</p>
        <p className="intro">{`Subscription Code: ${payload[0].payload.codes.join(', ')}`}</p>
      </div>
    );
  }
  return null;
};

const TreemapTooltip = ({ active, payload }) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
        <p className="label">{`Code: ${data.name}`}</p>
        <p className="intro">{`Total Seats: ${data.size}`}</p>
        <p className="intro">{`Paid: ${data.paid_seats} / Certified: ${data.certified_seats}`}</p>
      </div>
    );
  }
  return null;
};

const MixBarChart = ({ data, height, tooltipComponent: TooltipComponent, dataKey = "count" }) => {
  return (
    <BarChart
      width={600}
      height={height}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="start_date" />
      <YAxis />
      <Tooltip content={TooltipComponent} />
      <Bar dataKey={dataKey}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Bar>
    </BarChart>
  );
};

const TreemapChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <Treemap
        data={data}
        dataKey="size"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
      >
        <Tooltip content={<TreemapTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
};

const RadialChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="10%"
        outerRadius="80%"
        barSize={10}
        data={data}
      >
        <RadialBar
          minAngle={15}
          background
          clockWise
          dataKey="value"
          label={{ position: 'insideStart', fill: '#fff' }}
        />
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return (
                <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
                  <p className="label">{`${payload[0].payload.name}`}</p>
                  <p className="intro">{`Value: ${payload[0].value}`}</p>
                </div>
              );
            }
            return null;
          }}
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

const processChartData = (subscriptions: Subscription[]) => {
  if (!subscriptions?.length) return [];
  
  const chartData = subscriptions.reduce((acc, sub) => {
    if (!sub?.start_date) return acc;
    
    const startDate = new Date(sub.start_date).toLocaleDateString();
    const existingEntry = acc.find(entry => entry.start_date === startDate);
    if (existingEntry) {
      existingEntry.count += 1;
      existingEntry.codes.push(sub.code || 'Unknown');
    } else {
      acc.push({ start_date: startDate, count: 1, codes: [sub.code || 'Unknown'] });
    }
    return acc;
  }, []);
  
  if (!chartData.length) return [];
  
  chartData.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
  return fillMissingDates(chartData);
};

const processPriceData = (subscriptions: Subscription[]) => {
  if (!subscriptions?.length) return [];
  
  const priceData = subscriptions.reduce((acc, sub) => {
    if (!sub?.start_date) return acc;
    
    const startDate = new Date(sub.start_date).toLocaleDateString();
    const existingEntry = acc.find(entry => entry.start_date === startDate);
    if (existingEntry) {
      existingEntry.price += (sub.price_plan?.price || 0);
      existingEntry.codes.push(sub.code || 'Unknown');
    } else {
      acc.push({ 
        start_date: startDate, 
        price: (sub.price_plan?.price || 0), 
        codes: [sub.code || 'Unknown'] 
      });
    }
    return acc;
  }, []);
  
  if (!priceData.length) return [];
  
  priceData.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
  return fillMissingDates(priceData);
};

const fillMissingDates = (data: any[]) => {
  if (!data?.length) return [];
  
  try {
    const completeData = [];
    const startDate = new Date(data[0].start_date);
    const endDate = new Date(data[data.length - 1].start_date);

    let currentDate = startDate;
    while (currentDate <= endDate) {
      const dateString = currentDate.toLocaleDateString();
      const existingEntry = data.find(entry => entry.start_date === dateString);
      if (existingEntry) {
        completeData.push(existingEntry);
      } else {
        completeData.push({ 
          start_date: dateString, 
          count: 0, 
          price: 0,
          codes: [] 
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return completeData;
  } catch (error) {
    console.error('Error filling missing dates:', error);
    return [];
  }
};

const processTreemapData = (subscriptions: Subscription[]) => {
  if (!subscriptions?.length) return [];
  
  return subscriptions
    .filter(sub => sub?.code)
    .map(sub => ({
      name: sub.code || 'Unknown',
      size: (sub.paid_seats || 0) + (sub.certified_seats || 0),
      paid_seats: sub.paid_seats || 0,
      certified_seats: sub.certified_seats || 0
    }))
    .filter(item => item.size > 0);
};

const SubscriptionDashboard: React.FC<SubscriptionDashboardProps> = ({ subscriptions = [] }) => {
  const [expanded, setExpanded] = useState(false);

  const chartData = processChartData(subscriptions);
  const priceData = processPriceData(subscriptions);
  const treemapData = processTreemapData(subscriptions);
  const radialData = treemapData.map((item, index) => ({
    name: item.name,
    value: item.size,
    fill: COLORS[index % COLORS.length]
  }));

  if (!subscriptions.length) {
    return (
      <div className="bg-white shadow-sm rounded-lg overflow-hidden p-6">
        <h3 className="text-lg font-semibold text-gray-900">Dashboard</h3>
        <p className="text-gray-500 mt-2">No subscription data available</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Dashboard</h3>
          <button
            onClick={() => setExpanded(!expanded)}
            className="p-1 text-gray-600 hover:text-gray-900 transition-colors"
            aria-label={expanded ? 'Collapse dashboard' : 'Expand dashboard'}
          >
            {expanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
          </button>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">Subscriptions Count by Start Date</h4>
            <MixBarChart data={chartData} height={expanded ? 200 : 100} tooltipComponent={CustomTooltip} />
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">Subscription Price by Start Date</h4>
            <MixBarChart data={priceData} height={expanded ? 200 : 100} tooltipComponent={PriceTooltip} dataKey="price" />
          </div>
          {expanded && (
            <>
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">Subscription Seats Distribution</h4>
                <TreemapChart data={treemapData} />
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">Subscription Sizes</h4>
                <RadialChart data={radialData} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDashboard;
