import { signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { auth, db } from '../firebase';

export const signIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    // Get user data to verify account exists
    const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
    if (!userDoc.exists()) {
      throw new Error('User account not found');
    }
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in:', error);
    const errorMessage = error.code === 'auth/invalid-credential'
      ? 'Invalid email or password'
      : error.code === 'auth/user-not-found'
      ? 'No account found with this email'
      : 'Authentication failed. Please try again.';
    toast.error(errorMessage);
    throw error;
  }
};

export const isUserAdmin = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return false;
    const userData = userDoc.data();
    return userData && (
      userData.access_level === 'admin' || 
      userData.isAdmin === true || 
      userData.role === 'admin'
    );
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};
export async function getUsersBySearch(searchTerm: string) {
  if (!searchTerm || searchTerm.length < 2) return [];
  
  const usersRef = collection(db, 'users');
  const term = searchTerm.toLowerCase();
  
  // Get all users and filter client-side for now 
  // TODO: Add proper indexing for case-insensitive search
  const snapshot = await getDocs(usersRef);
  const results = new Map();
  
  snapshot.docs.forEach(doc => {
    const data = doc.data();
    const searchableText = `${data.firstName} ${data.lastName} ${data.email}`.toLowerCase();
    
    if (searchableText.includes(term)) {
      results.set(doc.id, { uid: doc.id, ...data });
    }
  });

  return Array.from(results.values());
}
