import { useState, useEffect } from "react";
import { X } from "lucide-react";
import { getUsersBySearch, isUserAdmin } from "../../lib/firebase/auth";
import { collection, query, where, getDocs, doc, getDoc, limit } from "firebase/firestore";
import { db } from "../../lib/firebase/config";
import { Pack } from "../../lib/firebase/types";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-hot-toast";

interface EditPackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (pack: Partial<Pack>) => void;
  pack?: Pack;
}

export default function EditPackModal({ isOpen, onClose, onSave, pack }: EditPackModalProps) {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{id: string, name: string}[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    if (user) {
      isUserAdmin(user.uid).then(setIsAdmin);
    }
  }, [user]);

  useEffect(() => {
    if (pack) {
      setName(pack.name || '');
    } else {
      setName('');
      setSelectedUsers([]);
    }
  }, [pack]);

  useEffect(() => {
    const loadMembers = async () => {
      if (!pack) {
        setSelectedUsers([]);
        return;
      }

      if (pack.members?.length) {
        try {
          const usersRef = collection(db, 'users');
          const memberData = [];
          for (const memberId of pack.members) {
            const userDoc = await getDoc(doc(usersRef, memberId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              memberData.push({
                id: memberId,
                name: `${userData.firstName} ${userData.lastName}`
              });
            }
          }
          setSelectedUsers(memberData);
        } catch (error) {
          console.error('Error loading members:', error);
        }
      } else {
        setSelectedUsers([]);
      }
    };
    loadMembers();
  }, [pack]);

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (isAdmin && term.length > 2) {
      const users = await getUsersBySearch(term);
      setSearchResults(users);
    } else {
      setSearchResults([]);
    }
  };

  const handleEmailSubmit = async () => {
    if (!emailInput) return;

    try {
      const normalizedEmail = emailInput.trim().toLowerCase();
      console.log('Searching for email:', normalizedEmail);
      
      const usersRef = collection(db, 'users');
      // Get all users and find match manually to handle case sensitivity
      const allUsersQuery = query(usersRef);
      const snapshot = await getDocs(allUsersQuery);

      // Find user with case-insensitive email match
      const userDoc = snapshot.docs.find(doc => {
        const userData = doc.data();
        return userData.email?.toLowerCase() === normalizedEmail;
      });

      if (!userDoc) {
        // Log available emails for debugging
        console.log('Available emails:', snapshot.docs.map(doc => doc.data().email));
        toast.error('Email not found. Please check the email address and try again.');
        return;
      }

      const userData = userDoc.data();
      
      // Check if user is already added
      if (selectedUsers.some(u => u.id === userDoc.id)) {
        toast.error('This user is already added to the pack');
        return;
      }

      addUser({ uid: userDoc.id, firstName: userData.firstName, lastName: userData.lastName });
      setEmailInput('');
      toast.success('User added successfully');
    } catch (error) {
      console.error('Error adding user by email:', error);
      toast.error('Failed to add user. Please try again.');
    }
  };

  const addUser = (user: any) => {
    setSelectedUsers(prev => [...prev, { id: user.uid, name: `${user.firstName} ${user.lastName}` }]);
    setSearchResults([]);
    setSearchTerm('');
  };

  const removeUser = (userId: string) => {
    setSelectedUsers(prev => prev.filter(u => u.id !== userId));
  };

  const handleSave = () => {
    onSave({
      name: name || pack?.name || '',
      members: Array.from(new Set([...selectedUsers.map(u => u.id), pack?.leaderId].filter(Boolean)))
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{pack ? 'Edit Pack' : 'New Pack'}</h2>
          <button onClick={onClose}><X className="h-5 w-5" /></button>
        </div>

        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Pack Name (optional)"
          className="w-full p-2 border rounded mb-4"
        />

        <div className="relative mb-4">
          {isAdmin ? (
            <>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search people by name or email"
                className="w-full p-2 border rounded"
              />
              {searchResults.length > 0 && (
                <div className="absolute w-full bg-white border rounded-b mt-1 max-h-40 overflow-y-auto">
                  {searchResults.map(user => (
                    <button
                      key={user.uid}
                      onClick={() => addUser(user)}
                      className="w-full p-2 text-left hover:bg-gray-100"
                    >
                      {user.firstName} {user.lastName} ({user.email})
                    </button>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="flex gap-2">
              <input
                type="email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="Enter user's email"
                className="flex-1 p-2 border rounded"
              />
              <button
                onClick={handleEmailSubmit}
                className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {selectedUsers.map(user => (
            <div key={user.id} className="bg-indigo-100 rounded-lg px-3 py-1 flex items-center gap-2">
              <span>{user.name}</span>
              <button onClick={() => removeUser(user.id)}><X className="h-4 w-4" /></button>
            </div>
          ))}
        </div>

        <button
          onClick={handleSave}
          className="w-full bg-indigo-600 text-white rounded p-2 hover:bg-indigo-700"
        >
          Save Changes
        </button>

        {pack && (
          <button
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this pack?')) {
                onSave({ id: pack.id, _delete: true });
                onClose();
              }
            }}
            className="w-full text-sm text-gray-500 hover:text-red-600 mt-4"
          >
            Delete this pack
          </button>
        )}
      </div>
    </div>
  );
}