import React, { useState } from 'react';
import type { Theme } from '../../lib/firebase/types';
import { CheckCircle } from 'lucide-react';

interface ThemeCardProps {
  theme: Theme;
  isCompleted?: boolean;
  isInProgress?: boolean;
  onStart: () => void;
  onViewSummary: () => void;
  onRevisit?: () => void;
}

export default function ThemeCard({ 
  theme, 
  isCompleted, 
  isInProgress,
  onStart, 
  onViewSummary,
  onRevisit 
}: ThemeCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150;
  const needsTruncation = theme.description.length > maxLength;
  const truncatedDescription = needsTruncation && !isExpanded 
    ? `${theme.description.slice(0, maxLength)}...` 
    : theme.description;

  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-xl font-semibold text-gray-900 mb-2">{theme.title}</h3>
          <div className="mb-4">
            <p className="text-gray-600">{truncatedDescription}</p>
            <div className="flex justify-between items-center mt-1">
              {needsTruncation && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="text-indigo-600 text-sm hover:text-indigo-700 focus:outline-none"
                >
                  {isExpanded ? 'Show less' : 'Read more'}
                </button>
              )}
              {isCompleted && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onStart();
                  }}
                  className="text-sm text-gray-500 hover:text-gray-700 focus:outline-none ml-auto"
                >
                  revisit
                </button>
              )}
            </div>
          </div>
          <div>
            <button
              onClick={isCompleted ? onViewSummary : onStart}
              className={`inline-flex items-center justify-center whitespace-nowrap px-4 py-2 rounded transition-colors ${
                isCompleted 
                  ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200' 
                  : 'bg-indigo-600 text-white hover:bg-indigo-700'
              }`}
            >
              {isCompleted ? 'View Summary' : (isInProgress ? 'Continue Theme' : 'Start Theme')}
            </button>
          </div>
        </div>
        <div className="ml-4">
          {isCompleted && (
            <CheckCircle className="w-5 h-5 text-green-600" />
          )}
          {isInProgress && !isCompleted && (
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
              In Progress
            </span>
          )}
        </div>
      </div>
    </div>
  );
} 