import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Pack, PackMessage } from '../../lib/firebase/types';
import { getUserPacks, getPackMessages, createPackMessage, createPack, updatePack, deletePack, getPackById } from '../../lib/firebase/packs';
import { useAuth } from '../../contexts/AuthContext';
import PackList from './PackList';
import PackMessages from './PackMessages';
import EditPackModal from './EditPackModal';
import { ChevronLeft, Users } from 'lucide-react';
import { collection, query, where, orderBy, onSnapshot, arrayRemove, doc, getDoc, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase/config';

interface PackMember {
  id: string;
  firstName: string;
  lastName: string;
}

export default function PacksPage() {
  const [searchParams] = useSearchParams();
  const initialPackId = searchParams.get('packId');
  const [packs, setPacks] = useState<Pack[]>([]);
  const [selectedPack, setSelectedPack] = useState<Pack | null>(null);
  const [messages, setMessages] = useState<PackMessage[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPack, setEditingPack] = useState<Pack | null>(null);
  const [showMembers, setShowMembers] = useState(false);
  const [packMembers, setPackMembers] = useState<PackMember[]>([]);
  const { user, userData } = useAuth();
  const membersRef = React.useRef<HTMLDivElement>(null);

  // Add click outside handler for members popover
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (membersRef.current && !membersRef.current.contains(event.target as Node)) {
        setShowMembers(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (user) {
      const searchParams = new URLSearchParams(window.location.search);
      const urlPackId = searchParams.get('packId');
      
      loadPacks().then(loadedPacks => {
        if (urlPackId) {
          const pack = loadedPacks.find(p => p.id === urlPackId);
          if (pack) setSelectedPack(pack);
        } else {
          const savedPackId = localStorage.getItem('selectedPackId');
          if (savedPackId) {
            const pack = loadedPacks.find(p => p.id === savedPackId);
            if (pack) setSelectedPack(pack);
          }
        }
      });
    }
  }, [user]);

  const loadPacks = async () => {
    if (user) {
      const userPacks = await getUserPacks(user.uid);
      setPacks(userPacks);
      return userPacks;
    }
    return [];
  };

  useEffect(() => {
    if (selectedPack) {
      // Set up real-time listener for messages with limit
      const q = query(
        collection(db, 'packMessages'),
        where('packId', '==', selectedPack.id),
        orderBy('createdAt', 'desc'), // Get newest messages first
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        // Create a Map to ensure uniqueness by message ID
        const uniqueMessages = new Map();
        
        // Process messages, ensuring uniqueness and proper order
        snapshot.docs.forEach(doc => {
          const message = { id: doc.id, ...doc.data() } as PackMessage;
          if (!uniqueMessages.has(message.id)) {
            uniqueMessages.set(message.id, message);
          }
        });

        // Convert to array and sort by creation time
        const sortedMessages = Array.from(uniqueMessages.values())
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        setMessages(sortedMessages);
      });

      // Cleanup listener on unmount or when selectedPack changes
      return () => unsubscribe();
    }
  }, [selectedPack]);

  const handleNewPack = () => {
    setEditingPack(null);
    setShowEditModal(true);
  };

  const handleEditPack = (pack: Pack) => {
    setEditingPack(pack);
    setShowEditModal(true);
  };

  const handleDeletePack = async (packId: string) => {
    try {
      await deletePack(packId);
      if (selectedPack?.id === packId) {
        setSelectedPack(null);
        localStorage.removeItem('selectedPackId');
      }
      await loadPacks();
    } catch (error) {
      console.error('Error deleting pack:', error);
    }
  };

  const handleLeavePack = async (packId: string) => {
    if (!user) return;
    
    try {
      // Update the pack to remove the user from members
      await updatePack(packId, {
        members: arrayRemove(user.uid) as unknown as string[]
      });
      
      if (selectedPack?.id === packId) {
        setSelectedPack(null);
        localStorage.removeItem('selectedPackId');
      }
      
      await loadPacks();
    } catch (error) {
      console.error('Error leaving pack:', error);
    }
  };

  const handleSavePack = async (packData: Partial<Pack>) => {
    if (!user) return;

    if (packData._delete && editingPack) {
      await handleDeletePack(editingPack.id);
      setShowEditModal(false);
      return;
    }

    if (editingPack) {
      await updatePack(editingPack.id, packData);
    } else {
      const newPack: Omit<Pack, 'id'> = {
        name: packData.name || 'New Pack',
        leaderId: user.uid,
        members: Array.from(new Set([...(packData.members || []), user.uid])),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        lastMessageAt: new Date().toISOString(),
        type: 'private'
      };
      await createPack(newPack);
    }
    loadPacks();
    setShowEditModal(false);
  };

  const handleSendMessage = async (content: string) => {
    if (!user || !userData || !selectedPack) return;

    const message = {
      packId: selectedPack.id,
      userId: user.uid,
      userName: `${userData.firstName} ${userData.lastName}`,
      content,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    try {
      await createPackMessage(selectedPack.id, message);
      // No need to call loadMessages here - the listener will handle it
    } catch (error) {
      console.error('Error creating message:', error);
    }
  };

  const handlePackSelect = (pack: Pack) => {
    setSelectedPack(pack);
    localStorage.setItem('selectedPackId', pack.id);
  };

  // Load initial pack if provided
  useEffect(() => {
    const loadSelectedPack = async () => {
      if (initialPackId) {
        try {
          const pack = await getPackById(initialPackId);
          if (pack) {
            setSelectedPack(pack);
          }
        } catch (error) {
          console.error('Error loading pack:', error);
        }
      }
    };

    loadSelectedPack();
  }, [initialPackId]);

  // Add function to load pack members
  const loadPackMembers = async (memberIds: string[]) => {
    const members: PackMember[] = [];
    for (const memberId of memberIds) {
      try {
        const userDoc = await getDoc(doc(db, 'users', memberId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          members.push({
            id: memberId,
            firstName: userData.firstName || '',
            lastName: userData.lastName || ''
          });
        }
      } catch (error) {
        console.error('Error loading member:', error);
      }
    }
    setPackMembers(members);
  };

  // Update useEffect to load members when pack changes
  useEffect(() => {
    if (selectedPack?.members) {
      loadPackMembers(Array.isArray(selectedPack.members) ? selectedPack.members : Object.keys(selectedPack.members));
    }
  }, [selectedPack]);

  return (
    <div className="h-[calc(100vh-64px)] flex bg-white">
      <div className="max-w-7xl w-full mx-auto flex">
        {/* Sidebar */}
        <div className={`w-full md:w-80 flex-shrink-0 bg-white border-r ${selectedPack ? 'hidden md:block' : ''}`}>
          <PackList
            packs={packs}
            selectedPackId={selectedPack?.id || null}
            onSelectPack={handlePackSelect}
            onNewPack={handleNewPack}
            onEditPack={handleEditPack}
            onDeletePack={handleDeletePack}
            onLeavePack={handleLeavePack}
          />
        </div>
        
        {/* Messages area */}
        <div className={`flex-1 flex flex-col overflow-hidden ${!selectedPack ? 'hidden md:flex md:items-center md:justify-center' : ''}`}>
          {selectedPack ? (
            <div className="h-full flex flex-col overflow-hidden">
              {/* Header */}
              <div className="flex-shrink-0 p-4 border-b flex items-center justify-between bg-white">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setSelectedPack(null)}
                    className="md:hidden p-2 hover:bg-gray-100 rounded-lg"
                  >
                    <ChevronLeft className="h-6 w-6" />
                  </button>
                  <h2 className="font-semibold">{selectedPack.name || 'Pack Messages'}</h2>
                </div>
                <div className="relative" ref={membersRef}>
                  <button
                    onClick={() => setShowMembers(!showMembers)}
                    className="p-2 hover:bg-gray-100 rounded-lg flex items-center space-x-2"
                  >
                    <Users className="h-5 w-5 text-gray-600" />
                    <span className="text-sm text-gray-600">{packMembers.length}</span>
                  </button>
                  {showMembers && (
                    <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border p-4 z-50">
                      <h3 className="text-sm font-medium text-gray-900 mb-3">Pack Members</h3>
                      <div className="space-y-2">
                        {packMembers.map(member => (
                          <div key={member.id} className="flex items-center space-x-2">
                            <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                              <span className="text-sm text-indigo-600">
                                {member.firstName[0]}{member.lastName[0]}
                              </span>
                            </div>
                            <span className="text-sm text-gray-700">
                              {member.firstName} {member.lastName}
                              {member.id === selectedPack.leaderId && (
                                <span className="ml-2 text-xs text-indigo-600">(Leader)</span>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Messages */}
              <div className="flex-1 overflow-hidden">
                <PackMessages
                  pack={selectedPack}
                  messages={messages}
                  onSendMessage={handleSendMessage}
                  packId={selectedPack.id}
                  setMessages={setMessages}
                />
              </div>
            </div>
          ) : (
            <div className="text-gray-500">
              Select a pack to view messages
            </div>
          )}
        </div>
      </div>

      <EditPackModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSave={handleSavePack}
        pack={editingPack || undefined}
      />
    </div>
  );
} 